import React, { useEffect, useState } from 'react'

import Footer from './Footer'
import { Link, useHistory, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import './Trand.css';
import axios from 'axios';
import { setSelectedSource } from '../actions/sourceAction'
import { ApiUrl } from '../common/UrlContant';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import Header from './Header';
import { useDispatch } from 'react-redux';
import { setSelectedCategory } from '../actions/CategoryAction';
import Loader from '../common/Loader';
import DateTime from '../common/DateTime';


export default function Trands() {
  const [Trands, setTrands] = useState([]);
  const [ListTrands, setListTrands] = useState([]);
  const [page, setPage] = useState(1);
  const [Catepage, setCatepage] = useState(1);
  const [Loading, setLoading] = useState(false)
  const [Topauthor, setTopauthor] = useState([])
  const [Next, setNext] = useState();
  const [Previous, setPrevious] = useState();
  const [Category, setCategory] = useState([])
  const [NextP, setNextP] = useState()
  const [Counter, setCounter] = useState()
  const [time, setTime] = useState([])
  const [search, setsearch] = useState('')
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigation = useNavigate();
 
  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    loop: true,
    slidesToShow: 8,
    paginging: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 5000,
          centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 5000,
          centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToScroll: 1
        }
      },
    ]

    // slidesToScroll: 8,

  };

  const Getcategory = () => {

    // e.preventDefault()
    if (search) {
      axios.get(ApiUrl + `api/news/category/?query=${search}`).then((res) => {

        setNextP(res.data.next)
        setCategory(res.data.results)
        setCounter(res.data.count)
      })
    }
    else {


      axios.get(ApiUrl + `api/news/category/?page=${Catepage}&size=40`).then((res) => {

        setNextP(res.data.next)
        setCategory(res.data.results)
        setCounter(res.data.count)
      })
    }

  }



  const Gettrands = () => {
    setLoading(true)
    if (page <= 1) {
      axios.get(ApiUrl + `api/news/all-news/?page=${page}&size=16`).then((res) => {

        setNext(res.data.next)
        setPrevious(res.data.previous)
        var SliderList = res.data.results.splice(0, 8);
        setTrands(SliderList);
        setListTrands(res.data.results);
        setLoading(false)

      })
    }
    else {
      axios.get(ApiUrl + `api/news/all-news/?page=${page}&size=8`).then((res) => {


        setNext(res.data.next)
        setPrevious(res.data.previous)
        setListTrands(res.data.results);
        setLoading(false)


      })
    }
  }

  const TopAuthor = () => {
    axios.get(ApiUrl + `api/news/source/?size=21`).then((res) => {
      setTopauthor(res.data.results)
    })
    
  }

  const GotoSourceDetaile = (Sname, Sid) => {
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedSource(Sid, Sname)
    ).then((res) => {
      const URL = Sname.replace(/\s+/g, '-')
      navigation('/author/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }
  const GotoNewsDetaile = (Sname, Sid) => {
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedSource(Sid, Sname)
    ).then((res) => {
      const URL =  Sname.replace(/\s+/g, '-')
      navigation('/news/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }
  const GotoCategoryDetaile = (Cname, Cid, Counter) => {
    localStorage.setItem('selectedCid', Cid);
    localStorage.setItem('selectedCname', Cname);
    localStorage.setItem('selectedCount', Counter);
    dispatch(
      setSelectedCategory(Cname, Cid, Counter)
    ).then((res) => {
      const URL = Cname.replace(/\s+/g, '-')
      navigation('/category/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }

  const fetchMoreData = () => {
    if (NextP) {
      setCatepage((Catepage) => Catepage + 1)
      if (search) {
        axios.get(ApiUrl + `api/news/category/?query=${search}`).then((res) => {

          setNextP(res.data.next)
          setCategory([...Category, ...res.data.results])
          setCounter(res.data.count)
        })
      }
      else {

        axios.get(ApiUrl + `api/news/category/?page=${Catepage}&size=40`).then((res) => {
          setNextP(res.data.next)
          setCategory([...Category, ...res.data.results])
          setCounter(res.data.count)
        })
      }

    }
  };



  useEffect(() => {
    Gettrands();
    TopAuthor();

  }, [page])
  useEffect(() => {
    Getcategory();

  }, [search])


  return (
    <div>
      {Loading ?
        <Loader /> : ""}
      <div className="main-wrap">
        {/* <aside id="sidebar-wrapper" className="custom-scrollbar offcanvas-sidebar position-right">
      <button className="off-canvas-close"><i className="ti-close" /></button>
      <div className="sidebar-inner">
        <div className="siderbar-widget mb-50 mt-30">
          <form action="#" method="get" className="search-form position-relative">
            <input type="text" className="search_field" placeholder="Search" defaultValue name="s" />
            <span className="search-icon"><i className="ti-search mr-5" /></span>
          </form>
        </div>
        <div className="sidebar-widget mb-50">
          <div className="widget-header mb-30">
            <h5 className="widget-title">Top <span>Trending</span></h5>
          </div>
          <div className="post-aside-style-2">
            <ul className="list-post">
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-2.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">4m ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-3.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">3h ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-5.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Reflections from a Token Black Friend</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">4h ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-7.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">How to Identify a Smart Person in 3 Minutes</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">5h ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-8.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Blackface Minstrel Songs Don’t Belong in Children’s Music Class</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">5h30 ago</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebar-widget widget_tag_cloud mb-50">
          <div className="widget-header tags-close mb-20">
            <h5 className="widget-title mt-5">Tags Cloud</h5>
          </div>
          <div className="tagcloud">
            <Link to="/category">Beauty</Link>
            <Link to="/category">Book</Link>
            <Link to="/category">Design</Link>
            <Link to="/category">Fashion</Link>
            <Link to="/category">Lifestyle</Link>
            <Link to="/category">Travel</Link>
            <Link to="/category">Science</Link>
            <Link to="/category">Health</Link>
            <Link to="/category">Sports</Link>
            <Link to="/category">Arts</Link>
            <Link to="/category">Books</Link>
            <Link to="/category">Style</Link>
          </div>
        </div>
        <div className="sidebar-widget widget-ads mb-30">
          <div className="widget-header tags-close mb-20">
            <h5 className="widget-title mt-5">Your Ads Here</h5>
          </div>
          <Link to="/assets/imgs/news-1.jpg" className="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
            <img className="border-radius-10" src="assets/imgs/ads-1.jpg" alt />
          </Link>
        </div>
      </div>
    </aside> */}
        <Header />
        <main className="position-relative">
          {/* <div className="post-carausel-1-items mb-50">
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <span className="top-right-icon bg-dark"><i className="mdi mdi-flash-on" /></span>
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-1.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">Reflections from a Token Black Friend</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">J. Stormvik</Link></span>
              <span className="post-on">2m ago</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-2.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">What Will It Take to Reopen the World to Travel?</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
              <span className="post-on">3m ago</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-3.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">H.Rosie</Link></span>
              <span className="post-on">13m ago</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-4.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">How line-dried laundry gets that fresh smell</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
              <span className="post-on">15m ago</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <span className="top-right-icon background10"><i className="mdi mdi-share" /></span>
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-5.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">Traveling tends to magnify all human emotions</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
              <span className="post-on">4m ago</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <span className="top-right-icon bg-dark"><i className="mdi mdi-flash-on" /></span>
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-6.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">How line-dried laundry gets that fresh smell</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">K. Stormvik</Link></span>
              <span className="post-on">12m ago</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="slider-single bg-white p-10 border-radius-15">
            <div className="img-hover-scale border-radius-10">
              <Link to="/single">
                <img className="border-radius-10" src="assets/imgs/news-7.jpg" alt="post-slider" />
              </Link>
            </div>
            <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
              <Link to="/single">Federal arrests show no sign that antifa plotted</Link>
            </h6>
            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
              <span className="post-by">By <Link to="/author">K. Henry</Link></span>
              <span className="post-on">14m ago</span>
            </div>
          </div>
        </div>
      </div> */}
          <Slider {...settings}>
            {
              Trands?.map((slide) => {
                return (


                  <div className="postheadtitle slider-single bg-white p-10 border-radius-15" >
                    <div className="img-hover-scale border-radius-10">
                      {/* <span className="top-right-icon bg-dark"><i className="mdi mdi-flash-on" /></span> */}
                      <a href={`/news/${slide.source?.name}`} onClick={() => GotoNewsDetaile(slide.source?.name, slide.id)}>
                        <img className="border-radius-10 cu_image" src={slide.image} alt="post-slider" />
                      </a>
                    </div>
                    <div className='postheadtitle'>
                      <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
                        <a href={`/news/${slide.source?.name}`} onClick={() => GotoNewsDetaile(slide.source?.name, slide.id)} >{slide.title}</a>
                      </h6>
                    </div>
                    
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
                      <span className="post-by">By <a href={`/author/${slide.source?.name}`} onClick={() => GotoSourceDetaile(slide.source?.name, slide.source?.id)}> {slide.source?.name}</a></span>

                    </div>
                  </div>
                )
              })
            }

          </Slider>


          <div className="container">
            {/* <div className="row">
          <div className="col-12 text-center mb-50">
            <Link to="/#">
              <img className="border-radius-10 d-inline" src="assets/imgs/ads.jpg" alt="post-slider" />
            </Link>
          </div>
        </div> */}
            <div className="row">

              <div className="col-lg-3 col-md-3 primary-sidebar sticky-sidebar sidebar-left order-2 order-md-1">

               <DateTime/>
                <div className="sidebar-widget widget_categories_2 border-radius-10 bg-white mb-30">
                  <ul className="font-small text-muted">
                    <li className="cat-item cat-item-2 active"><Link to="/#"><span className="mr-10">
                      <ion-icon name="earth-outline" />
                    </span>International</Link></li>
                    <li className="cat-item cat-item-3"><Link to="/#"><span className="mr-10">
                      <ion-icon name="trending-up-outline" />
                    </span>Businesss</Link></li>
                    <li className="cat-item cat-item-4"><Link to="/#"><span className="mr-10">
                      <ion-icon name="glasses-outline" />
                    </span>Entertainment</Link></li>
                    <li className="cat-item cat-item-5"><Link to="/#"><span className="mr-10">
                      <ion-icon name="bicycle-outline" />
                    </span>Sport News</Link></li>
                    <li className="cat-item cat-item-6"><Link to="/#"><span className="mr-10">
                      <ion-icon name="fitness-outline" />
                    </span>Health</Link></li>
                    <li className="cat-item cat-item-2"><Link to="/#"><span className="mr-10">
                      <ion-icon name="book-outline" />
                    </span>Magazine</Link></li>
                  </ul>
                </div>

                <div className="sidebar-widget widget_categories border-radius-10 bg-white mb-30">
                  <div className="widget-header position-relative mb-15 header-style-2">
                    <h5 className="widget-title"><strong>{Counter} Categories</strong></h5>
                    <form class="search-form  position-relative  ">
                      <input onChange={(e) => { setsearch(e.target.value) }} type="text" class="search_field w-100" placeholder="Search Category" />
                      <span onClick={() => Getcategory()} class="search-icon "><i class="ti-search mr-5"></i></span>
                    </form>
                  </div>
                  <ul className="font-small text-muted" style={{ height: "calc(100vh - 200px)", overflow: "auto" }}>
                    <InfiniteScroll
                      dataLength={Category.length}
                      next={() => fetchMoreData()}
                      // loader={<h4>Loading ... </h4>}
                      hasMore={true}
                      scrollThreshold={0.5}
                    // scrollableTarget="scrollableDiv"
                    >
                      {
                        Category?.map((item) => {
                          return (
                            <li className="cat-item cat-item-2 " onClick={() => GotoCategoryDetaile(item.name, item.id, item.news_count)} >
                              <Link className='d-flex align-items-center justify-content-between w-100' >
                              <span className='leftconinspn' style={{fontFamily:"Times Roman"}}>{item.name}</span><span className='rightconinspn'>{item.news_count}</span>
                              </Link></li>
                          )
                        })
                      }
                    </InfiniteScroll>
                  </ul>
                </div>
              </div>

              <div className="col-lg-9 col-md-9 order-1 order-md-2">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="latest-post mb-30">
                      <div className="widget-header position-relative mb-30">
                        <div className="row">
                          <div className="col-7">
                            <h4 className="widget-title mb-0">Latest <span>Trends</span></h4>
                          </div>

                        </div>
                      </div>
                      <div className="loop-list-style-1">

                        {ListTrands.map((item) => {
                          return (
                            item.image &&

                            <article className="p-10 background-white border-radius-10 mb-30 wow fadeIn animated" >
                              <div className="d-flex">
                                <div className="post-thumb d-flex mr-15 border-radius-15 img-hover-scale">
                                  <a href={`/news/${item.source?.name}`} className="color-white" onClick={() => GotoNewsDetaile(item.source?.name, item.id)} >
                                    <img className="border-radius-15" src={item.image} alt />
                                  </a>
                                </div>
                                <div className="post-content media-body">
                                  <div className="entry-meta mb-15 mt-10">
                                    <Link className="entry-meta meta-2" onClick={() => GotoCategoryDetaile(item.category?.name, item.category?.id, item.category?.news_count)}><span className="post-in text-danger font-x-small">{item.category?.name}</span></Link>
                                  </div>
                                  <h5 className="post-title mb-15 text-limit-2-row">
                                    {/* <span className="post-format-icon">
                                      <ion-icon name="videocam-outline" />
                                    </span> */}
                                    <a href={`/news/${item.source?.name}`} onClick={() => GotoNewsDetaile(item.source?.name, item.id)}>{item.title}</a></h5>
                                  <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                    <span className="post-by">By <a href={`/author/${item.source?.name}`} onClick={() => GotoSourceDetaile(item.source?.name, item.source?.id)}>{item.source?.name}</a></span>
                                    {/* <span className="post-on">{item.added_on}</span> */}
                                    <span className="time-reading d-none d-lg-block">{new Date(item.published_on).getFullYear()}-{new Date(item.published_on).getMonth() + 1}-{new Date(item.published_on).getDate()}  &nbsp; {new Date(item.published_on).getHours()}:{new Date(item.published_on).getMinutes()}</span>
                                  </div>
                                </div>
                              </div>
                            </article>
                          )
                        })}

                      </div>
                    </div>
                    <div className="pagination-area mb-30">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          {
                            Previous ?
                              <li onClick={() => setPage((page) => page - 1)} className="page-item active mr-30"><Link className="page-link" ><i className="ti-angle-left" /></Link></li>
                              : ""}

                          {
                            Next ?
                              <li onClick={() => setPage((page) => page + 1)} className="page-item active ml-30"><Link className="page-link"><i className="ti-angle-right" /></Link></li>
                              : ""
                          }
                        </ul>
                      </nav>
                    </div>
                    
                    {/* <div className="sidebar-widget">
                      <div className="widget-header position-relative mb-20">
                        <div className="row">
                          <div className="col-7">
                            <h5 className="widget-title mb-0">Videos <span>News</span></h5>
                          </div>
                          <div className="col-5 text-right">
                            <h6 className="font-medium pr-15">
                              <Link className="text-muted font-small" to="/#">View more</Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="block-tab-item post-module-1 post-module-4">
                        <div className="row">
                          <div className="slider-single col-md-6 mb-30">
                            <div className="img-hover-scale border-radius-10">
                              <span className="top-right-icon background10"><i className="mdi mdi-share" /></span>
                              <Link to="/single">
                                <img className="border-radius-10" src="assets/imgs/news-17.jpg" alt="post-slider" />
                              </Link>
                              <div className="play_btn play_btn_small">
                                <Link className="play-video" to="/https://vimeo.com/333834999" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
                                  <i className="fa fa-play" />
                                </Link>
                              </div>
                            </div>
                            <h5 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
                              <Link to="/single">What Will It Take to Reopen the World to Travel?</Link>
                            </h5>
                            <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                              <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>30k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>1.5k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-share-alt" aria-hidden="true" /></span>15k</span>
                              <Link className="float-right" to="/#"><i className="ti-bookmark" /></Link>
                            </div>
                          </div>
                          <div className="slider-single col-md-6 mb-30">
                            <div className="img-hover-scale border-radius-10">
                              <Link to="/single">
                                <img className="border-radius-10" src="assets/imgs/news-18.jpg" alt="post-slider" />
                              </Link>
                              <div className="play_btn play_btn_small">
                                <Link className="play-video" to="/https://vimeo.com/333834999" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
                                  <i className="fa fa-play" />
                                </Link>
                              </div>
                            </div>
                            <h5 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
                              <Link to="/single">Many Races, Little Time: Cycling Faces a Crowded Calendar</Link>
                            </h5>
                            <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                              <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-share-alt" aria-hidden="true" /></span>125k</span>
                              <Link className="float-right" to="/#"><i className="ti-bookmark" /></Link>
                            </div>
                          </div>
                          <div className="slider-single col-md-6 mb-30">
                            <div className="img-hover-scale border-radius-10">
                              <Link to="/single">
                                <img className="border-radius-10" src="assets/imgs/news-19.jpg" alt="post-slider" />
                              </Link>
                              <div className="play_btn play_btn_small">
                                <Link className="play-video" to="/https://vimeo.com/333834999" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
                                  <i className="fa fa-play" />
                                </Link>
                              </div>
                            </div>
                            <h5 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
                              <Link to="/single">Marathon Runners Build Their Sport Stride by Stride</Link>
                            </h5>
                            <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                              <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-share-alt" aria-hidden="true" /></span>125k</span>
                              <Link className="float-right" to="/#"><i className="ti-bookmark" /></Link>
                            </div>
                          </div>
                          <div className="slider-single col-md-6 mb-30">
                            <div className="img-hover-scale border-radius-10">
                              <Link to="/single">
                                <img className="border-radius-10" src="assets/imgs/news-20.jpg" alt="post-slider" />
                              </Link>
                              <div className="play_btn play_btn_small">
                                <Link className="play-video" to="/https://vimeo.com/333834999" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
                                  <i className="fa fa-play" />
                                </Link>
                              </div>
                            </div>
                            <h5 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
                              <Link to="/single">Casa Dani, From a Michelin Chef, to Open in Manhattan West</Link>
                            </h5>
                            <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                              <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                              <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-share-alt" aria-hidden="true" /></span>125k</span>
                              <Link className="float-right" to="/#"><i className="ti-bookmark" /></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-4 col-md-12 sidebar-right">

                    <div className="sidebar-widget mb-30">
                      <div className="widget-top-auhor border-radius-10 p-20 bg-white">
                        <div className="widget-header widget-header-style-1 position-relative mb-15">
                          <h5 className="widget-title pl-5">Top <span>Authors</span></h5>
                        </div>
                        {
                          Topauthor.map((item) => {
                            return (

                              <Link onClick={() => GotoSourceDetaile(item.name, item.id)} className="red-tooltip active" data-toggle="tooltip" data-placement="top" title data-original-title="Emma - 1034 posts"><img src={item.logo} alt />
                                <p>{item.news_count}</p>
                              </Link>
                            )
                          })
                        }
                      </div>
                    </div>
                    {/* <div className="sidebar-widget widget_newsletter border-radius-10 p-20 bg-white mb-30">
                      <div className="widget-header widget-header-style-1 position-relative mb-15">
                        <h5 className="widget-title">Newsletter</h5>
                      </div>
                      <div className="newsletter">
                        <p className="font-medium">Heaven fruitful doesn't over les idays appear creeping</p>
                        <form target="_blank" action="#" method="get" className="subscribe_form relative mail_part">
                          <div className="form-newsletter-cover">
                            <div className="form-newsletter position-relative">
                              <input type="email" name="EMAIL" placeholder="Put your email here" required />
                              <button type="submit">
                                <i className="ti ti-email" />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="sidebar-widget mb-30">
                      <div className="widget-header mb-30">
                        <h5 className="widget-title">Top <span>Trending</span></h5>
                      </div>
                      <div className="post-aside-style-2">
                        <ul className="list-post">
                          <li className="mb-30 wow fadeIn animated">
                            <div className="d-flex">
                              <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                                <Link className="color-white" to="/single">
                                  <img src="assets/imgs/thumbnail-2.jpg" alt />
                                </Link>
                              </div>
                              <div className="post-content media-body">
                                <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h6>
                                <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                  <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                                  <span className="post-on">4m ago</span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="mb-30 wow fadeIn animated">
                            <div className="d-flex">
                              <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                                <Link className="color-white" to="/single">
                                  <img src="assets/imgs/thumbnail-3.jpg" alt />
                                </Link>
                              </div>
                              <div className="post-content media-body">
                                <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link></h6>
                                <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                  <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                                  <span className="post-on">3h ago</span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="mb-30 wow fadeIn animated">
                            <div className="d-flex">
                              <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                                <Link className="color-white" to="/single">
                                  <img src="assets/imgs/thumbnail-5.jpg" alt />
                                </Link>
                              </div>
                              <div className="post-content media-body">
                                <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Reflections from a Token Black Friend</Link></h6>
                                <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                  <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                                  <span className="post-on">4h ago</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="sidebar-widget mb-30">
                      <div className="widget-header mb-30">
                        <h5 className="widget-title">Most <span>Popular</span></h5>
                      </div>
                      <div className="post-aside-style-3">
                        <article className="bg-white border-radius-15 mb-30 p-10 wow fadeIn animated">
                          <div className="post-thumb d-flex mb-15 border-radius-15 img-hover-scale">
                            <Link to="/single">
                              <video autoPlay className="photo-item__video" loop muted preload="none">
                                <source src="https://player.vimeo.com/external/210754416.sd.mp4?s=826dbe91a402d3fc239674b6595a0100b2a45098&profile_id=164&oauth2_token_id=57447761" type="video/mp4" />
                              </video>
                            </Link>
                          </div>
                          <div className="pl-10 pr-10">
                            <h5 className="post-title mb-15"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h5>
                            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase mb-10">
                              <span className="post-in">In <Link to="/category">Global</Link></span>
                              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                              <span className="post-on">4m ago</span>
                            </div>
                          </div>
                        </article>
                        <article className="bg-white border-radius-15 mb-30 p-10 wow fadeIn animated">
                          <div className="post-thumb d-flex mb-15 border-radius-15 img-hover-scale">
                            <Link to="/single">
                              <img className="border-radius-15" src="assets/imgs/news-22.jpg" alt />
                            </Link>
                          </div>
                          <div className="pl-10 pr-10">
                            <h5 className="post-title mb-15"><Link to="/single">Fight breaks out at Disneyland</Link></h5>
                            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase mb-10">
                              <span className="post-in">In <Link to="/category">Healthy</Link></span>
                              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                              <span className="post-on">14m ago</span>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                    <div className="sidebar-widget p-20 border-radius-15 bg-white widget-latest-comments wow fadeIn animated">
                      <div className="widget-header mb-30">
                        <h5 className="widget-title">Last <span>Comments</span></h5>
                      </div>
                      <div className="post-block-list post-module-6">
                        <div className="last-comment mb-20 d-flex wow fadeIn animated">
                          <span className="item-count vertical-align">
                            <Link className="red-tooltip author-avatar" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Stormvik - 985 posts"><img src="assets/imgs/authors/author-14.png" alt /></Link>
                          </span>
                          <div className="alith_post_title_small">
                            <p className="font-medium mb-10"><Link to="/single">A writer is someone for whom writing is more difficult than it is for other people.</Link></p>
                            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase mb-10">
                              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                              <span className="post-on">4m ago</span>
                            </div>
                          </div>
                        </div>
                        <div className="last-comment d-flex wow fadeIn animated">
                          <span className="item-count vertical-align">
                            <Link className="red-tooltip author-avatar" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Harry - 1245 posts"><img src="assets/imgs/authors/author-9.png" alt /></Link>
                          </span>
                          <div className="alith_post_title_small">
                            <p className="font-medium mb-10"><Link to="/single">Riding the main trail was easy, a little bumpy because my mountain bike is a hardtail</Link></p>
                            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase mb-10">
                              <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                              <span className="post-on">4m ago</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <div className="row mb-50 mt-15">
              <div className="col-md-12">
                <div className="widget-header position-relative mb-30">
                  <h4 className="widget-title mb-0">From <span>Blog</span></h4>
                </div>
                <div className="post-carausel-2 post-module-1 row">
                  <div className="col">
                    <div className="post-thumb position-relative">
                      <div className="thumb-overlay img-hover-slide border-radius-15 position-relative" style={{}}>
                        <Link className="img-link" to="/single" />
                        <div className="post-content-overlay">
                          <div className="entry-meta meta-0 font-small mb-15">
                            <Link to="/category"><span className="post-cat bg-success color-white">Travel</span></Link>
                          </div>
                          <h5 className="post-title">
                            <Link className="color-white" to="/single">Tesla’s Cooking Up A New Way To Wire Its Cars, Report Says</Link>
                          </h5>
                          <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                            <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                            <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="post-thumb position-relative">
                      <div className="thumb-overlay img-hover-slide border-radius-15 position-relative" style={{}}>
                        <Link className="img-link" to="/single" />
                        <div className="post-content-overlay">
                          <div className="entry-meta meta-0 font-small mb-15">
                            <Link to="/category"><span className="post-cat bg-info color-white">Beauty</span></Link>
                          </div>
                          <h5 className="post-title">
                            <Link className="color-white" to="/single">Ratcliffe to be Director of nation talent Trump ignored</Link>
                          </h5>
                          <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                            <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                            <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="post-thumb position-relative">
                      <div className="thumb-overlay img-hover-slide border-radius-15 position-relative" style={{}}>
                        <Link className="img-link" to="/single" />
                        <div className="post-content-overlay">
                          <div className="entry-meta meta-0 font-small mb-15">
                            <Link to="/category"><span className="post-cat bg-danger color-white">Art</span></Link>
                          </div>
                          <h5 className="post-title">
                            <Link className="color-white" to="/single">Countries seek ‘new history’ as figures are re-examined</Link>
                          </h5>
                          <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                            <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                            <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="post-thumb position-relative">
                      <div className="thumb-overlay img-hover-slide border-radius-15 position-relative" style={{}}>
                        <Link className="img-link" to="/single" />
                        <div className="post-content-overlay">
                          <div className="entry-meta meta-0 font-small mb-10">
                            <Link to="/category"><span className="post-cat bg-warning color-white">Gaming</span></Link>
                          </div>
                          <h5 className="post-title">
                            <Link className="color-white" to="/single">The secret to moving this ancient sphinx screening</Link>
                          </h5>
                          <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                            <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                            <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="post-thumb position-relative">
                      <div className="thumb-overlay img-hover-slide border-radius-15 position-relative" style={{}}>
                        <Link className="img-link" to="/single" />
                        <div className="post-content-overlay">
                          <div className="entry-meta meta-0 font-small mb-10">
                            <Link to="/category"><span className="post-cat bg-primary color-white">Garden</span></Link>
                          </div>
                          <h5 className="post-title">
                            <Link className="color-white" to="/single">Harbour amid a Slowen down in singer city screening</Link>
                          </h5>
                          <div className="entry-meta meta-1 font-x-small mt-10 pr-5 pl-5 text-muted">
                            <span><span className="mr-5"><i className="fa fa-eye" aria-hidden="true" /></span>5.8k</span>
                            <span className="ml-15"><span className="mr-5 text-muted"><i className="fa fa-comment" aria-hidden="true" /></span>2.5k</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
      <div className="dark-mark" />
    </div>
  )
}
