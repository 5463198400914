import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ApiUrl } from '../common/UrlContant'
import "./MobileCountryPage.css"
import { setSelectedCountry } from '../actions/CountryAction'
import { useDispatch } from 'react-redux'

export default function MobileCountryPage() {
    const [CountryList, setCountryList] = useState([])
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const GetCountry = () => {
        // if (CountrySearch)
        //   axios.get(ApiUrl + `api/news/country/?query=${CountrySearch}`).then((res) => {
        //     setCountryList(res.data.countries);
        //   })
        // else
        axios.get(ApiUrl + `api/news/country/`).then((res) => {
            setCountryList(res.data.countries);
        })
    }
    const GotoCountryDetaile = (Cname, Cid) => {
        localStorage.setItem('selectedCountryid', Cid);
        localStorage.setItem('selectedCountryname', Cname);
        dispatch(
            setSelectedCountry(Cname, Cid)
        ).then((res) => {
            const URL = Cname.replace(/\s+/g, '-')
            navigation('/Country/' + URL);

        }).catch((err) => {
            console.log("redux ---", err);
        })
    }

    useEffect(() => {
        GetCountry();
    }, [])

    return (
        <>
            <Header />
            <div>
                <div className='custom-grid'>
                    {
                        CountryList?.map((item) => {
                            // debugger;

                              return (
                              item.news_count == 0 ? '' :  <li className="cat-item cat-item-2 " onClick={() => GotoCountryDetaile(item.name, item.id)} >
                              <Link className='falge d-block'>
                                  <img src={item.image} />
                                  <p className='d-inline-block mb-0 text-white flage-number position-absolute'>{item.news_count}</p>
                              </Link>
                          </li>

                            )
                        }
                        )
                    }                    
                </div>
            </div>

        </>
    )
}
