import { SEARCH } from "../actions/confige";

const initialState ={
    slectedSearchname : ''
};

export default function SearchReducer(state = initialState , action){
    switch(action.type){
        case SEARCH : 
        return{
            ...state,
            slectedSearchname  : action.selectedSearch
            
        }
      
        default :
        return state
    }
}