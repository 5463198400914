import {SELECTED_COUNTRYNAME , SELECTED_COUNTRYID } from "./confige";

export const setSelectedCountry = ( selectedCountryname , selectedCountryid ) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTED_COUNTRYNAME , selectedCountryname },
            {type:SELECTED_COUNTRYID , selectedCountryid },
            
        );
        resolve();
    })
}