import { SELECTED_FINANCE ,SELECTED_FINANCENAME } from "../actions/confige";

const initialState ={
    slectedfinance : '',
    slectedfinancename : ''
};

export default function FinanceReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_FINANCE : 
        return{
            ...state,
            slectedfinance  : action.selectedfinance
            
        }
        case SELECTED_FINANCENAME : 
        return{
            ...state,
            slectedfinancename  : action.selectedfinancename
            
        }
      
        default :
        return state
    }
}