import { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ApiUrl } from '../common/UrlContant'
import InfiniteScroll from 'react-infinite-scroll-component'
import { setSelectedSource } from '../actions/sourceAction'
import { useDispatch } from 'react-redux'
import { setSelectedCategory } from '../actions/CategoryAction'
import { setSelectedCountry } from '../actions/CountryAction'
import Loader from '../common/Loader'
import DateTime from '../common/DateTime'
import { setSelectedMeta } from '../actions/MetaAction'
import Slider from 'react-slick'
import { CountryId, TabTitle } from '../common/ByDomain'
import { setSelectedTab } from '../actions/TabAction'
import { cacheable } from '../common/Caches'

function Home() {
  const [Category, setCategory] = useState([]);
  const [GetNews, setGetNews] = useState([])
  const [GetNewsListView, setGetNewsListView] = useState([])
  const [page, setPage] = useState(1);
  const [Interpage, setInterPage] = useState(1);
  const [International, setInternational] = useState([])
  const [Next, setNext] = useState();
  const [InterNext, setInterNext] = useState();
  const [Catepage, setCatepage] = useState(1);
  const [InterPrevious, setInterPrevious] = useState();
  const [Previous, setPrevious] = useState();
  const [CountryCount, setCountryCount] = useState()
  const [Topauthor, setTopauthor] = useState([])
  const [Counter, setCounter] = useState()
  const [search, setsearch] = useState('')
  const [Loading, setLoading] = useState(false)
  const [CountrySearch, setCountrySearch] = useState('');
  const [CountryList, setCountryList] = useState([])
  const [Count, setCount] = useState({})
  const [NextP, setNextP] = useState()
  const [loadcount, setloadcount] = useState(0)
  const [Interloadcount, setInterloadcount] = useState(0)
  const dispatch = useDispatch();
  const navigation = useNavigate();

  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 4000,
    loop: true,
    slidesToShow: 8,
    // slidesToScroll: 1,
    paginging: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 5000,
          centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 5000,
          centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToScroll: 1
        }
      },
    ]

    // slidesToScroll: 8,

  };

  const StaticCount = () => {
    axios.get(ApiUrl + `api/news/counter-statistic/`).then((res) => {
      setCount(res.data);
    })
  }

  const PageToptop = () => {
    window.scrollTo(0, 0);
  }

  const GetAllNews = () => {
    setLoading(true)

    if (page <= 1)
      axios.get(ApiUrl + `api/news/all-news?page=${page}&size=22&country_id=${CountryId}`).then((res) => {
        setloadcount(res.data.count)

        var SliderList = res.data.results.splice(0, 12);
        setGetNews(SliderList)
        setGetNewsListView(res.data.results)
        Metadispatch(TabTitle, 'News Of Country', 'https://basevik.fra1.cdn.digitaloceanspaces.com/default/site_share.jpg')
        setLoading(false)

      })
    else

      axios.get(ApiUrl + `api/news/all-news?page=${page}&size=10&country_id=${CountryId}`).then((res) => {
        if (page <= 1) {

          setloadcount(res.data.count)
          setGetNewsListView(res.data.results)
        } else {
          setGetNewsListView([...GetNewsListView, ...res.data.results])
        }
        setLoading(false)


      })


  }
  const GetInternationalNews = () => {
    setLoading(true)
    axios.get(ApiUrl + `api/news/all-news?page=${page}&size=20`).then((res) => {
      setInterloadcount(res.data.count)

      if (page <= 1)
        setInternational(res.data.results)
      else {
        setInternational([...International, ...res.data.results])

      }
      setLoading(false)


    })

  }
  const Metadispatch = (title, desc, image) => {
    dispatch(
      setSelectedMeta(title, desc, image)
    ).then((res) => {
      // setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
      console.log(res);
    })
  }

  const TopAuthor = () => {

    axios.get(ApiUrl + `api/news/source/?size=21`).then((res) => {
      setTopauthor(res.data.results)
      setLoading(false)
    })
  }

  const CountryStatic = () => {

    axios.get(ApiUrl + `api/news/counter-statistic/`).then((res) => {
      setCountryCount(res.data.total_news_country)
    })
  }

  const GetCountry = () => {

    // setLoading(false)

    if (CountrySearch)
      axios.get(ApiUrl + `api/news/country/?query=${CountrySearch}`).then((res) => {
        setCountryList(res.data.countries);
      })
    else
      axios.get(ApiUrl + `api/news/country/`).then((res) => {
        setCountryList(res.data.countries);
      })
  }

  const Getcategory = () => {
    // setLoading(false)

    // e.preventDefault()
    if (search) {
      axios.get(ApiUrl + `api/news/category/?query=${search}`).then((res) => {

        setNextP(res.data.next)
        setCategory(res.data.results)
        setCounter(res.data.count)
      })
    }
    else {


      axios.get(ApiUrl + `api/news/category/?page=${Catepage}&size=40`).then((res) => {

        setNextP(res.data.next)
        setCategory(res.data.results)
        setCounter(res.data.count)
      })
    }

  }
  const fetchMoreData = () => {

    if (NextP) {
      setCatepage((Catepage) => Catepage + 1)
      if (search) {
        axios.get(ApiUrl + `api/news/category/?query=${search}`).then((res) => {

          setNextP(res.data.next)
          setCategory([...Category, ...res.data.results])
          setCounter(res.data.count)
          setLoading(false)
        })
      }
      else {

        axios.get(ApiUrl + `api/news/category/?page=${Catepage}&size=40`).then((res) => {
          setNextP(res.data.next)
          setCategory([...Category, ...res.data.results])
          setCounter(res.data.count)
          setLoading(false)
        })
      }

    }
  };
  const GotoNewsDetaile = (Sname, Sid) => {
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedSource(Sid, Sname)
    ).then((res) => {
      // const URL =  Sname.replace(/\s+/g, '-')
      navigation('/news/' + Sname.replace(/\s+/g, '-'));

    }).catch((err) => {
      console.log("redux ---", err);
    })
  };
  const GotoSourceDetaile = (Sname, Sid) => {
    debugger;
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedTab(Sname),
      setSelectedSource(Sid, Sname),

    ).then((res) => {
      const URL = Sname.replace(/\s+/g, '-')
      navigation('/author/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  };
  const GotoCategoryDetaile = (Cname, Cid, Counter) => {
    localStorage.setItem('selectedCid', Cid);
    localStorage.setItem('selectedCname', Cname);
    localStorage.setItem('selectedCount', Counter);
    dispatch(
      setSelectedCategory(Cname, Cid, Counter)
    ).then((res) => {
      const URL = Cname.replace(/\s+/g, '-')
      navigation('/category/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }
  const GotoCountryDetaile = (Cname, Cid) => {
    localStorage.setItem('selectedCountryid', Cid);
    localStorage.setItem('selectedCountryname', Cname);
    dispatch(
      setSelectedCountry(Cname, Cid)
    ).then((res) => {
      const URL = Cname.replace(/\s+/g, '-')
      navigation('/Country/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }

  // const fetchMoreNews = () => {

  //   if (Next)
  //     setPage((page) => page + 1)
  //   // setLoading(true)
  //   if (page <= 1)
  //     axios.get(ApiUrl + `api/news/all-news?page=${page}&size=22&country_id=${CountryId}`).then((res) => {
  //       setNext(res.data.next)
  //       setPrevious(res.data.previous)
  //       var SliderList = res.data.results.splice(0, 12);
  //       setGetNews(SliderList)
  //       setGetNewsListView([...GetNewsListView, ...res.data.results])
  //       Metadispatch(SliderList[0]?.title, SliderList[0]?.summary, SliderList[0]?.image)
  //       setLoading(false)

  //     })
  //   else
  //     axios.get(ApiUrl + `api/news/all-news?page=${page}&size=10&country_id=${CountryId}`).then((res) => {

  //       setNext(res.data.next)
  //       setPrevious(res.data.previous)
  //       setGetNewsListView([...GetNewsListView, ...res.data.results])
  //       setLoading(false)


  //     })

  // }

  // const fetchMoreInternationNews = () => {

  //   if (InterNext) {
  //     // setLoading(true)
  //     setInterPage((Interpage) => Interpage + 1)
  //     if (page <= 1)
  //       axios.get(ApiUrl + `api/news/all-news?page=${Interpage}&size=20`).then((res) => {
  //         setInterNext(res.data.next)
  //         setInterPrevious(res.data.previous)
  //         setInternational([...International, ...res.data.results])
  //         setLoading(false)

  //       })
  //     else
  //       axios.get(ApiUrl + `api/news/all-news?page=${Interpage}&size=20`).then((res) => {

  //         setInterNext(res.data.next)
  //         setInterPrevious(res.data.previous)
  //         setInternational([...International, ...res.data.results])
  //         setLoading(false)


  //       })
  //   }
  // }


  useEffect(() => {
    TopAuthor();
    StaticCount();
    cacheable(localStorage.setItem('testList', JSON.stringify(GetNews)))
    cacheable(localStorage.setItem('testList1', JSON.stringify(GetNewsListView)))
    CountryStatic();
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => { GetCountry(); }, [CountrySearch])
  useEffect(() => {
    Getcategory();
  }, [search])

  useEffect(() => {
    GetAllNews();
    GetInternationalNews();
  }, [page])
  return (
    <>
      <Header />
      {Loading ?
        <Loader /> : ''}
      <Slider {...settings} className='d-none d-lg-block' >
        {
          GetNews?.map((slide) => {
            return (


              <div className="postheadtitle slider-single bg-white p-10 border-radius-15" style={{ width: "70% !important" }}  >
                <div className="img-hover-scale border-radius-10">
                  {/* <span className="top-right-icon bg-dark"><i className="mdi mdi-flash-on" /></span> */}
                  <a href={`/news/${slide.title.replace(/\s+/g, '-')}`} onClick={() => GotoNewsDetaile(slide.title, slide.id)}>
                    <img className="border-radius-10 cu_image" src={slide.image} alt="post-slider" />
                  </a>
                </div>
                <div className='postheadtitle'>
                  <h6 className="post-title pr-5 pl-5 mb-10 mt-15 text-limit-2-row">
                    <a href={`/news/${slide.title.replace(/\s+/g, '-')}`} onClick={() => GotoNewsDetaile(slide.title, slide.id)} >{slide.title}</a>
                  </h6>
                </div>

                <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase pl-5 pb-15">
                  <span className="post-by">By <a href={`/author/${slide.source?.name}`} onClick={() => GotoSourceDetaile(slide.source?.name, slide.source?.id)}> {slide.source?.name}</a></span>

                </div>
              </div>
            )
          })
        }

      </Slider>

      <main className="position-relative">
        <div className="container-fluid">
          <div className="entry-header entry-header-1 mb-30 mt-50">
            {/* <div className="entry-meta meta-0 font-small mb-30"><Link onClick={() => GotoCategoryDetaile(List.category?.name, List.category?.id, List.category?.news_count)} ><span className="post-cat bg-success color-white">{List.category?.name}</span></Link></div> */}
            <h1 className="post-title mb-30">
              {/* {List.title} */}
            </h1>
            <div className="entry-meta meta-1 font-x-small color-grey text-uppercase">
              {/* <span className="post-by">By <Link onClick={() => GotoSourceDetaile(List.source?.name, List.source?.id)}>{List.source?.name} </Link></span> */}
              {/* <span className="post-on">{new Date(List.published_on).getFullYear()}-{new Date(List.published_on).getMonth() + 1}-{new Date(List.published_on).getDate()}  &nbsp; {new Date(List.published_on).getHours()}:{new Date(List.published_on).getMinutes()}</span> */}
              {/* <span className="time-reading">12 mins read</span> */}
              {/* <p className="font-x-small mt-10">
              <span className="hit-count"><i className="ti-comment mr-5" />82 comments</span>
              <span className="hit-count"><i className="ti-heart mr-5" />68 likes</span>
              <span className="hit-count"><i className="ti-star mr-5" />8/10</span>
            </p> */}
            </div>
          </div>
          <div className=" row"> {/* d-lg-flex justfy-content-between custum_gaps  mb-50 */}


            <div className='col-lg-9'>

              <div className='row'>

                <div className="col-xl-4">
                  <div>
                    {/* <h4>Sweden News</h4> */}
                  </div>
                  <div className="loop-list-style-1 single_grid">
                    {/* 
                    <InfiniteScroll
                      dataLength={GetNewsListView.length}
                      next={() => fetchMoreNews()}
                      // loader={<h4>Loading ... </h4>}
                      hasMore={true}
                      scrollThreshold={0.5}

                    // scrollableTarget="scrollableDiv"
                    > */}


                    {GetNewsListView.map((item) => {
                      return (

                        <article className="p-10 background-white border-radius-10  wow fadeIn animated mb-30 ">
                          <div className="d-flex ">
                            <div className="post-thumb d-flex mr-15 border-radius-15 img-hover-scale">
                              <a href={`/news/${item.title.replace(/\s+/g, '-')}`} className="color-white" onClick={() => GotoNewsDetaile(item.title, item.id)}>
                                <img className="border-radius-15 cut_image" src={item.image} alt />
                              </a>
                            </div>
                            <div className="post-content media-body" style={{ width: "calc(100% - 125px)" }}>
                              <div className="entry-meta cu-my">
                                <Link className="entry-meta meta-2" onClick={() => GotoCategoryDetaile(item.category?.name, item.category?.id, item.category?.news_count)}><span className="post-in text-danger font-x-small">{item.category?.name}</span></Link>
                              </div>
                              <p className="post-title text-limit-2-row cu-mb">

                                <a href={`/news/${item.title.replace(/\s+/g, '-')}`} onClick={() => GotoNewsDetaile(item.title, item.id)}>{item.title}</a></p>
                              <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                <span className="post-by">By <a href={`/author/${item.source?.name}`} onClick={() => GotoSourceDetaile(item.source?.name, item.source?.id)}>{item.source?.name}</a></span>
                                {/* <span className="post-on"></span> */}
                                <div className='mt-1 d-none d-lg-block'><span className="time-reading">{new Date(item.published_on).getFullYear()}-{new Date(item.published_on).getMonth() + 1}-{new Date(item.published_on).getDate()}  &nbsp; {new Date(item.published_on).getHours()}:{new Date(item.published_on).getMinutes()}</span></div>
                              </div>
                            </div>
                          </div>
                        </article>
                      )
                    })}
                    {/* </InfiniteScroll> */}

                  </div>

                </div>

                <div className='col-xl-8'>
                  {/* d-xl-flex align-items-center cutom_gaps1 w-100 */}

                  <div className="">

                    <div className="loop-list-style-1 row"> {/* custom_grid */}


                      {International.map((item) => {
                        return (

                          <>
                            <div className='col-sm-6 '>
                              <article className="p-10 background-white border-radius-10 mb-30 wow fadeIn animated"> 
                                <div className="d-flex">
                                  <div className="post-thumb d-flex mr-15 border-radius-15 img-hover-scale">
                                    <a href={`/news/${item.title.replace(/\s+/g, '-')}`} className="color-white" onClick={() => GotoNewsDetaile(item.title, item.id)}>
                                      <img className="border-radius-15" src={item.image} alt />
                                    </a>
                                  </div>
                                  <div className="post-content media-body" style={{ width: "calc(100% - 150px)" }}>
                                    <div className="entry-meta cu-my">
                                      <Link className="entry-meta meta-2" onClick={() => GotoCategoryDetaile(item.category?.name, item.category?.id, item.category?.news_count)}><span className="post-in text-danger font-x-small">{item.category?.name}</span></Link>
                                    </div>
                                    <p className="post-title cu-mb text-limit-2-row">

                                      <a href={'/news/' + item.title.replace(/\s+/g, '-')} onClick={() => GotoNewsDetaile(item.title, item.id)}>{item.title}</a></p>
                                    <div className="entry-meta  font-x-small color-grey float-left text-uppercase">
                                      <div><span className="post-by">By <a href={`/author/${item.source?.name}`} onClick={() => GotoSourceDetaile(item.source?.name, item.source?.id)}>{item.source?.name}</a></span></div>
                                      {/* <span className="post-on"></span> */}
                                      <div className='mt-1 d-none d-sm-block'><span className="time-reading">{new Date(item.published_on).getFullYear()}-{new Date(item.published_on).getMonth() + 1}-{new Date(item.published_on).getDate()}  &nbsp; {new Date(item.published_on).getHours()}:{new Date(item.published_on).getMinutes()}</span></div>
                                    </div>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </>
                        )
                      })}
                    </div>


                  </div>


                  {/* <div className="bt-1 border-color-1 mb-30" /> */}
                  {/* <figure className="single-thumnail mb-30">
                  <img src={List.image} alt="" />

                  <div className="credit mt-15 font-small color-grey">
                    <i className="ti-credit-card mr-5" /><span>Image credit: pexels.com</span>
                  </div>
                </figure> */}
                  {/* <div className="single-excerpt">
                  <p className="font-large">detaile</p>
                </div> */}

                </div>

                <div className='col-12'>
                  <div className="pagination-area mb-30" style={{ marginTop: "30px", }}>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                          {
                            GetNewsListView.length < loadcount ?
                              <button onClick={() => setPage((page) => page + 1)} class="custom-btn btn-11"><span onClick={() => PageToptop()} >Load More...</span></button>
                              : ''
                          }

                        </div>

                      </ul>
                    </nav>
                  </div>
                </div>

              </div>

            </div>




            <div className="sidebar-right sticky-sidebar d-none d-lg-block col-lg-3">
              <div >
                <div className="sidebar-widget mb-30">
                  <div className="widget-header position-relative mb-30">
                    <div className="row">
                    </div>
                  </div>
                  <div className="sidebar-widget mb-30">
                    <DateTime />

                  </div>

                </div>
                <div className="sidebar-widget mb-30">
                  <div className="sidebar-widget widget_categories border-radius-10 bg-white mb-30 p-4">
                    <div className="widget-header position-relative mb-15 header-style-2">
                      <h5 className="widget-title" style={{ textAlign: "center" }}><strong>{CountryCount} Country</strong></h5>
                      <form class="search-form  position-relative  ">
                        <input onChange={(e) => setCountrySearch(e.target.value)} type="text" class="search_field w-100" placeholder="Search Country" />
                        <span class="search-icon "><i class="ti-search mr-5"></i></span>
                      </form>
                    </div>
                    <ul className="font-small text-muted" style={{ height: "509px", overflow: "auto" }}>
                      {/* <InfiniteScroll
                      dataLength={Category.length}
                      next={() => fetchMoreData()}
                      // loader={<h4>Loading ... </h4>}
                      hasMore={true}
                      scrollThreshold={0.5}
                    // scrollableTarget="scrollableDiv"
                    >
                      { */}
                      {
                        CountryList?.map((item) => {
                          return (
                            <li className="cat-item cat-item-2 " onClick={() => GotoCountryDetaile(item.name, item.id)} >
                              <Link className='d-flex align-items-center justify-content-between w-100'>
                                <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>{item.name}</span>
                                <span className='rightconinspn'>{item.news_count}</span>
                              </Link>
                            </li>
                          )
                        })
                      }
                      {/* </InfiniteScroll> */}
                    </ul>
                  </div>
                </div>




                <div className="sidebar-widget mb-30">
                  <div className="widget-top-auhor border-radius-10 p-20 bg-white">

                    <div className="widget-header widget-header-style-1 position-relative mb-15">
                      <h5 className="widget-title pl-5" style={{ textAlign: "center" }}>Top <span>Authors</span></h5>
                    </div>
                    {
                      Topauthor.map((item) => {
                        return (

                          <a href={`/author/${item.name}`} onClick={() => GotoSourceDetaile(item.name, item.id)} className="red-tooltip active" data-toggle="tooltip" data-placement="top" title data-original-title="Emma - 1034 posts"><img src={item.logo} alt />
                            <p>{item.news_count}</p>
                          </a>
                        )
                      })
                    }
                  </div>


                </div>

                <div className="sidebar-widget mb-50">

                  <div className="widget-top-auhor border-radius-10 p-20 bg-white">
                    <div className="widget-header widget-header-style-1 position-relative mb-15 header-style-2">
                      <h5 className="widget-title pl-5" style={{ textAlign: "center" }}>{Counter} Category </h5>
                      <form class="search-form  position-relative  ">
                        <input onChange={(e) => { setsearch(e.target.value) }} type="text" class="search_field w-100" placeholder="Search Category" />
                        <span onClick={() => Getcategory()} class="search-icon "><i class="ti-search mr-5 "></i></span>
                      </form>
                    </div>
                    <ul className="font-small text-muted" id='scrollable-target' style={{ height: "509px", overflow: "auto" }}>
                      <InfiniteScroll
                        dataLength={Category.length}
                        next={() => fetchMoreData()}
                        // loader={<h4>Loading ... </h4>}
                        hasMore={true}
                        scrollThreshold={0.5}
                        scrollableTarget="scrollable-target"
                      >
                        {
                          Category?.map((item) => {
                            return (
                              item.news_count == 0 ? '' :
                                <li className="cat-item cat-item-2 " onClick={() => GotoCategoryDetaile(item.name, item.id, item.news_count)}>
                                  <Link className='d-flex align-items-center justify-content-between w-100' style={{ display: "flex", padding: "5px 0px 5px 0px", fontSize: "13px" }} >
                                    <span style={{ fontFamily: "Times Roman", fontWeight: "600" }} className='leftconinspn'>{item.name}</span>
                                    <span className='rightconinspn' style={{ marginRight: "7px" }} >{item.news_count}</span>
                                  </Link>
                                </li>

                            )
                          })
                        }
                      </InfiniteScroll>
                    </ul>

                  </div>

                </div>
                <div div className="widget-top-auhor border-radius-10 p-20 bg-white">
                  {/* 
                    <div className="widget-header widget-header-style-1 position-relative mb-15">
                      <h5 className="widget-title pl-5">Top <span>Authors</span></h5>
                    </div> */}
                  <ul className="float-left mr-30 w-100 font-medium">
                    <li className="cat-item cat-item-2">
                      {/* <Link to="/category">Total News &nbsp; &nbsp; {Count.total_news}</Link> */}
                      <Link className='mnglscontent pl-0 pr-2' style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>Total News</span>
                        <span className='rightconinspn'>{Count.total_news}</span>
                      </Link>
                    </li>
                    <li className="cat-item cat-item-3">
                      <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'> Total Category</span>
                        <span className='rightconinspn'>{Count.total_news_category}</span>
                      </Link>
                    </li>
                    <li className="cat-item cat-item-4">
                      <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'> News Source</span>
                        <span className='rightconinspn'>{Count.total_news_source}</span>
                      </Link>


                    </li>
                    <li className="cat-item cat-item-5">
                      <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>News Language</span>
                        <span className='rightconinspn'>{Count.total_news_language}</span>
                      </Link>
                    </li>

                    <li className="cat-item cat-item-6">
                      <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>News Country</span>
                        <span className='rightconinspn'>{Count.total_news_country}</span>
                      </Link>
                    </li>

                  </ul>
                </div>
              </div>

            </div>
          </div>
          {/* {Flag.length ? <div className="mb-50">
              <h5>Countries related to this news: </h5>
              {
                Flag?.map((item) => {

                  return (<>
                    <img onClick={() => GotoCountryDetaile(item.name, item.id)} height='100px' width='100px' src={item.image} />
                  </>)
                })

              }

            </div> : ''} */}




        </div>
      </main>
      <Footer />
    </>

  )
}
export default Home