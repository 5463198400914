import { SELECTED_CATCOUNT, SELECTED_CATNAME , SELECTED_CATID } from "./confige";

export const setSelectedCategory = ( selectedCname , selectedCid , selectedCount) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTED_CATNAME , selectedCname },
            {type:SELECTED_CATID , selectedCid },
            {type:SELECTED_CATCOUNT , selectedCount }
        );
        resolve();
    })
}