import React from 'react'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Header from './Header'

export default function Error() {
  return (
    <div>
  {/* <div id="">
    <div className="preloader d-flex align-items-center justify-content-center">
      <div className="preloader-inner position-relative">
        <div className="text-center">
          <img className="jump mb-50" src="assets/imgs/loading.svg" alt />
          <h6>Now Loading</h6>
          <div className="loader">
            <div className="bar bar1" />
            <div className="bar bar2" />
            <div className="bar bar3" />
          </div>
        </div>
      </div>
    </div>
  </div> */}
  <div className="main-wrap">
    <aside id="sidebar-wrapper" className="custom-scrollbar offcanvas-sidebar position-right">
      <button className="off-canvas-close"><i className="ti-close" /></button>
      <div className="sidebar-inner">
        <div className="siderbar-widget mb-50 mt-30">
          <form action="#" method="get" className="search-form position-relative">
            <input type="text" className="search_field" placeholder="Search" defaultValue name="s" />
            <span className="search-icon"><i className="ti-search mr-5" /></span>
          </form>
        </div>
        <div className="sidebar-widget mb-50">
          <div className="widget-header mb-30">
            <h5 className="widget-title">Top <span>Trending</span></h5>
          </div>
          <div className="post-aside-style-2">
            <ul className="list-post">
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-2.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">4m ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-3.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">3h ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-5.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Reflections from a Token Black Friend</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">4h ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mb-30 wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-7.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">How to Identify a Smart Person in 3 Minutes</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">5h ago</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="wow fadeIn animated">
                <div className="d-flex">
                  <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                    <Link className="color-white" to="/single">
                      <img src="assets/imgs/thumbnail-8.jpg" alt />
                    </Link>
                  </div>
                  <div className="post-content media-body">
                    <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Blackface Minstrel Songs Don’t Belong in Children’s Music Class</Link></h6>
                    <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                      <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                      <span className="post-on">5h30 ago</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="sidebar-widget widget_tag_cloud mb-50">
          <div className="widget-header tags-close mb-20">
            <h5 className="widget-title mt-5">Tags Cloud</h5>
          </div>
          <div className="tagcloud">
            <Link to="/category">Beauty</Link>
            <Link to="/category">Book</Link>
            <Link to="/category">Design</Link>
            <Link to="/category">Fashion</Link>
            <Link to="/category">Lifestyle</Link>
            <Link to="/category">Travel</Link>
            <Link to="/category">Science</Link>
            <Link to="/category">Health</Link>
            <Link to="/category">Sports</Link>
            <Link to="/category">Arts</Link>
            <Link to="/category">Books</Link>
            <Link to="/category">Style</Link>
          </div>
        </div>
        <div className="sidebar-widget widget-ads mb-30">
          <div className="widget-header tags-close mb-20">
            <h5 className="widget-title mt-5">Your Ads Here</h5>
          </div>
          <Link to="/assets/imgs/news-1.jpg" className="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
            <img className="border-radius-10" src="assets/imgs/ads-1.jpg" alt />
          </Link>
        </div>
      </div>
    </aside>
<Header/>
    <main className="position-relative">
      <div className="container">
        <div className="row mb-30">
          <div className="col-12">
            <div className="content-404 text-center mb-30">
              <h1 className="mb-30">404</h1>
              <p>The page you were looking for could not be found.</p>
              <p className="text-muted">You may have typed the address incorrectly or you may have used an outdated link, or that page is traveling on Mars :) </p>
              <h6 className="mt-50 mb-15">Try search our site</h6>
              <form action="#" method="get" className="search-form d-lg-flex open-search mb-30">
                <i className="icon-search" />
                <input className="form-control" name="name" id="search" type="text" placeholder="Search..." />
              </form>
              <p>Visit the <Link to="/">Homepage</Link> or <Link to="/contact">Contact us</Link> about the problem</p>
            </div>
          </div>
        </div>
      </div>
    </main>
<Footer/>
  </div> 
  <div className="dark-mark" />
</div>

  )
}
