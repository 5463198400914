import { SELECTEDTAB} from "../actions/confige";

const initialState ={
    selectedTab : '',
    
};

export default function TabReducer(state = initialState , action){
    switch(action.type){
        case SELECTEDTAB : 
        return{
            ...state,
            selectedTab  : action.selectedTabname
            
        }
       
        default :
        return state
    }
}