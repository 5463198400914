export const SELECTED_SOURCE = 'SELECTED_SOURCE';
export const SELECTED_AUTHORNAME = 'SELECTED_AUTHORNAME';
export const SELECTED_CATID = 'SELECTED_CATID';
export const SELECTED_CATNAME = 'SELECTED_CATNAME';
export const SELECTED_CATCOUNT = 'SELECTED_CATCOUNT';
export const SELECTED_COUNTRYNAME = 'SELECTED_COUNTRYNAME';
export const SELECTED_COUNTRYID = 'SELECTED_COUNTRYID';
export const SELECTED_TITLE = 'SELECTED_TITLE';
export const SELECTED_DESCRIPTION = 'SELECTED_DESCRIPTION';
export const SELECTED_IMAGE = 'SELECTED_IMAGE';
export const SEARCH = 'SEARCH';
export const SELECTED_FINANCE = 'SELECTED_FINANCE';
export const SELECTED_FINANCENAME = 'SELECTED_FINANCENAME';
export const SELECTEDTAB = 'SELECTEDTAB';
