import { SELECTED_COUNTRYID , SELECTED_COUNTRYNAME } from "../actions/confige";

const initialState ={
    selectedCountryId : '',
    slectedCountryname : ''
};

export default function CountryReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_COUNTRYID : 
        return{
            ...state,
            selectedCountryId  : action.selectedCountryid
            
        }
        case SELECTED_COUNTRYNAME :
            return{
                ...state,
                slectedCountryname : action.selectedCountryname
            }
        default :
        return state
    }
}