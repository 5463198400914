import React, { useDebugValue, useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ApiUrl } from '../common/UrlContant'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSource } from '../actions/sourceAction'
import InfiniteScroll from 'react-infinite-scroll-component'
import { setSelectedCategory } from '../actions/CategoryAction'
import { setSelectedCountry } from '../actions/CountryAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet, HelmetProvider } from "react-helmet-async";
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import Loader from '../common/Loader'
import MetaTag from '../common/MetaTag'
import { setSelectedMeta } from '../actions/MetaAction'
import { htmlPrefilter } from 'jquery'
import { cacheable } from '../common/Caches'
// import {Img} from '../../public/assets/imgs/logo.svg'

export default function Single() {
  const SourceId = useSelector((state) => state.SourceObj.selectedSourceId || localStorage.getItem('selectedSource'));
  const AuthorName = useSelector((state) => state.SourceObj.slectedAuthername || localStorage.getItem('authorname'));
  const [List, setList] = useState({})
  const [Category, setCategory] = useState([])
  const [NextP, setNextP] = useState()
  const [Counter, setCounter] = useState()
  const [Count, setCount] = useState({})
  const [page, setPage] = useState(1);
  const [Catepage, setCatepage] = useState(1);
  const [Loading, setLoading] = useState(false)
  const [search, setsearch] = useState('')
  const [Flag, setFlag] = useState([])
  const [Tag, setTag] = useState([])

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const Getnews = () => {
    setLoading(true)
    axios.get(ApiUrl + `api/news/get-news/${SourceId}`).then((res) => {
      setList(res.data.news);
      setFlag(res.data.news.about_countries)
      setTag(res.data.news.keywords)

      Metadispatch(res.data.news.title, res.data.news.summary, res.data.news.image)
      setLoading(false)
    })
  }
  const StaticCount = () => {
    axios.get(ApiUrl + `api/news/counter-statistic/`).then((res) => {
      setCount(res.data);
    })
  }
  const GotoCountryDetaile = (Cname, Cid) => {
    localStorage.setItem('selectedCountryid', Cid);
    localStorage.setItem('selectedCountryname', Cname);
    dispatch(
      setSelectedCountry(Cname, Cid)
    ).then((res) => {
      const URL = Cname.replace(/\s+/g, '-')
      navigation('/Country/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }
  const GotoSourceDetaile = (Sname, Sid) => {
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedSource(Sid, Sname)
    ).then((res) => {
      const URL = Sname.replace(/\s+/g, '-')
      navigation('/author/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }


  const Getcategory = () => {

    // e.preventDefault()
    if (search) {
      axios.get(ApiUrl + `api/news/category/?query=${search}`).then((res) => {

        setNextP(res.data.next)
        setCategory(res.data.results)
        setCounter(res.data.count)
      })
    }
    else {


      axios.get(ApiUrl + `api/news/category/?page=${Catepage}&size=40`).then((res) => {

        setNextP(res.data.next)
        setCategory(res.data.results)
        setCounter(res.data.count)
      })
    }

  }

  const fetchMoreData = () => {
    if (NextP) {
      setCatepage((Catepage) => Catepage + 1)
      if (search) {
        axios.get(ApiUrl + `api/news/category/?query=${search}`).then((res) => {

          setNextP(res.data.next)
          setCategory([...Category, ...res.data.results])
          setCounter(res.data.count)
        })
      }
      else {

        axios.get(ApiUrl + `api/news/category/?page=${Catepage}&size=40`).then((res) => {
          setNextP(res.data.next)
          setCategory([...Category, ...res.data.results])
          setCounter(res.data.count)
        })
      }

    }
  };

  const GotoCategoryDetaile = (Cname, Cid, Counter) => {
    localStorage.setItem('selectedCid', Cid);
    localStorage.setItem('selectedCname', Cname);
    localStorage.setItem('selectedCount', Counter);
    dispatch(
      setSelectedCategory(Cname, Cid, Counter)
    ).then((res) => {
      const URL = Cname.replace(/\s+/g, '-')
      navigation('/category/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }
  const Metadispatch = (title, desc, image) => {
    setTimeout(() => {

      dispatch(
        setSelectedMeta(title, desc, image)
      ).then((res) => {
        // setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
        console.log(res);
      })
    }, 2000);
  }



  useEffect(() => {
    Getnews();
    
    StaticCount();
    // Metadispatch();
    cacheable(localStorage.setItem('testList',JSON.stringify(List)))
    window.scrollTo(0, 0);
  }, [])

  useEffect(()=>{Getcategory();},[search])


  return (
    <div>
      {/* <MetaTag title={List.source?.title} image={List.source?.logo} description={List.source?.description} /> */}

      {Loading ?
        <Loader /> : ""}
      <div className="main-wrap">
        <aside id="sidebar-wrapper" className="custom-scrollbar offcanvas-sidebar position-right">
          <button className="off-canvas-close"><i className="ti-close" /></button>
          <div className="sidebar-inner">
            <div className="siderbar-widget mb-50 mt-30">
              <form action="#" method="get" className="search-form position-relative">
                <input type="text" className="search_field" placeholder="Search" defaultValue name="s" />
                <span className="search-icon"><i className="ti-search mr-5" /></span>
              </form>
            </div>
            <div className="sidebar-widget mb-50">
              <div className="widget-header mb-30">
                <h5 className="widget-title">Top <span>Trending</span></h5>
              </div>
              <div className="post-aside-style-2">
                <ul className="list-post">
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src={Link.image} alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">4m ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-3.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">3h ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-5.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Reflections from a Token Black Friend</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">4h ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-7.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">How to Identify a Smart Person in 3 Minutes</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">5h ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-8.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Blackface Minstrel Songs Don’t Belong in Children’s Music Class</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">5h30 ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sidebar-widget widget_tag_cloud mb-50">
              <div className="widget-header tags-close mb-20">
                <h5 className="widget-title mt-5">Tags Cloud</h5>
              </div>
              <div className="tagcloud">
                <Link to="/category">Beauty</Link>
                <Link to="/category">Book</Link>
                <Link to="/category">Design</Link>
                <Link to="/category">Fashion</Link>
                <Link to="/category">Lifestyle</Link>
                <Link to="/category">Travel</Link>
                <Link to="/category">Science</Link>
                <Link to="/category">Health</Link>
                <Link to="/category">Sports</Link>
                <Link to="/category">Arts</Link>
                <Link to="/category">Books</Link>
                <Link to="/category">Style</Link>
              </div>
            </div>
            <div className="sidebar-widget widget-ads mb-30">
              <div className="widget-header tags-close mb-20">
                <h5 className="widget-title mt-5">Your Ads Here</h5>
              </div>
              <Link to="/assets/imgs/news-1.jpg" className="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
                <img className="border-radius-10" src="assets/imgs/ads-1.jpg" alt="" />
              </Link>
            </div>
          </div>
        </aside>
        <Header />
        <main className="position-relative">
          <div className="container">
            <div className="entry-header entry-header-1 mb-30 mt-50">
              <div className="entry-meta meta-0 font-small mb-30"><Link onClick={() => GotoCategoryDetaile(List.category?.name, List.category?.id, List.category?.news_count)} ><span className="post-cat bg-success color-white">{List.category?.name}</span></Link></div>
              <h1 className="post-title mb-30">
                {List.title}
              </h1>
              <div className="entry-meta meta-1 font-x-small color-grey text-uppercase">
                <span className="post-by">By <Link onClick={() => GotoSourceDetaile(List.source?.name, List.source?.id)}>{List.source?.name} </Link></span>
                <span className="post-on">{new Date(List.published_on).getFullYear()}-{new Date(List.published_on).getMonth() + 1}-{new Date(List.published_on).getDate()}  &nbsp; {new Date(List.published_on).getHours()}:{new Date(List.published_on).getMinutes()}</span>
                {/* <span className="time-reading">12 mins read</span> */}
                {/* <p className="font-x-small mt-10">
              <span className="hit-count"><i className="ti-comment mr-5" />82 comments</span>
              <span className="hit-count"><i className="ti-heart mr-5" />68 likes</span>
              <span className="hit-count"><i className="ti-star mr-5" />8/10</span>
            </p> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                {/* <div className="single-social-share single-sidebar-share mt-30">
                  <ul>
                    <li><Link className="social-icon facebook-icon text-xs-center" target="_blank" to="/#"><i className="ti-facebook" /></Link></li>
                    <li><Link className="social-icon twitter-icon text-xs-center" target="_blank" to="/#"><i className="ti-twitter-alt " /></Link></li>
                    <li><Link className="social-icon pinterest-icon text-xs-center" target="_blank" to="/#"><i className="ti-pinterest" /></Link></li>
                    <li><Link className="social-icon instagram-icon text-xs-center" target="_blank" to="/#"><i className="ti-instagram" /></Link></li>
                    <li><Link className="social-icon linkedin-icon text-xs-center" target="_blank" to="/#"><i className="ti-linkedin" /></Link></li>
                    <li><Link className="social-icon email-icon text-xs-center" target="_blank" to="/#"><i className="ti-email" /></Link></li>
                  </ul>
                </div> */}
                <div className="bt-1 border-color-1 mb-30" />
                <figure className="single-thumnail mb-30">
                  <img src={List.image} alt="" />
                  <div className="credit mt-15 font-small color-grey">
                    {/* <i className="ti-credit-card mr-5" /><span>Image credit: pexels.com</span> */}
                  </div>
                </figure>
                <div className="single-excerpt">
                  <div className="font-large" dangerouslySetInnerHTML={{ __html: List.details }} />
                </div>


                <div className='mt-4' style={{ display: "flex", flexWrap: 'wrap' }}><strong>Tag : &nbsp; </strong>
                  {

                    Tag.map((item) => {
                      return (
                        <p style={{ background: 'lightgrey', margin: '2px', padding: '2px 8px', borderRadius: '6px' }}>{item}</p>
                      )
                    })
                  }
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sidebar-right sticky-sidebar">
                <div className="pl-lg-50">
                  <div className="sidebar-widget mb-30">
                    {/* <div className="widget-header position-relative mb-30">
                      <div className="row">
                        <div className="col-7">
                          <h4 className="widget-title mb-0">Don't <span>miss</span></h4>
                        </div>
                        <div className="col-5 text-right">
                          <h6 className="font-medium pr-15">
                            <Link className="text-muted font-small" to="/#">View all</Link>
                          </h6>
                        </div>
                      </div>
                    </div> */}
                    <div className="post-aside-style-1 border-radius-10 p-20 bg-white">
                      <a href={List.source?.url} target='_blank'>
                        <h4 className="widget-title mb-0">{List.source?.name}</h4>

                        <div className="post-aside-style-2">
                          <ul className="list-post">
                            <li className="mb-30 wow fadeIn  animated" style={{ "visibility": "visible", "-webkit-animation-name": "_fadeIn", "animation-name": "_fadeIn" }}>
                              <div className="d-flex mt-4">
                                <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                                  <Link className="color-white" >
                                    <img src={List.source?.logo} alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row"><Link >{List.source?.title}</Link></h6>

                                </div>
                              </div>
                              <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                {/* <span className="post-by">By <Link to="/author">Stormvik</Link></span> */}
                                <div className="post-on"> {List.source?.description} </div>
                              </div>
                            </li>


                          </ul>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="sidebar-widget mb-30">
                    <div className="widget-top-auhor border-radius-10 p-20 bg-white">
                      <div className="widget-header widget-header-style-1 position-relative mb-15 header-style-2">
                        <h5 className="widget-title pl-5" style={{ textAlign: "center" }}>{Counter} Category </h5>
                        <form class="search-form  position-relative  ">
                          <input onChange={(e) => { setsearch(e.target.value) }} type="text" class="search_field w-100" placeholder="Search Category" />
                          <span onClick={() => Getcategory()} class="search-icon "><i class="ti-search mr-5"></i></span>
                        </form>
                      </div>
                      <ul className="font-small text-muted" style={{ height: "509px", overflow: "auto" }}>
                        <InfiniteScroll
                          dataLength={Category.length}
                          next={() => fetchMoreData()}
                          // loader={<h4>Loading ... </h4>}
                          hasMore={true}
                          scrollThreshold={0.5}
                        // scrollableTarget="scrollableDiv"
                        >
                          {
                            Category?.map((item) => {
                              return (
                                <li className="cat-item cat-item-2 " onClick={() => GotoCategoryDetaile(item.name, item.id, item.news_count)}>
                                  <Link className='d-flex align-items-center justify-content-between w-100' style={{  padding: "5px 0px 5px 0px", fontSize: "13px" }} >
                                    <span style={{ fontFamily: "Times Roman", fontWeight: "600" }} className='leftconinspn'>{item.name}</span>
                                    <span className='rightconinspn' style={{ marginRight: "7px" }} >{item.news_count}</span>
                                  </Link>
                                </li>

                              )
                            })
                          }
                        </InfiniteScroll>
                      </ul>

                    </div>
                  </div>

                  <div className="sidebar-widget mb-50">
                    <div className="sidebar-widget mb-30">

                      <div className="widget-top-auhor border-radius-10 p-20 bg-white">

                        <ul className="float-left mr-30 w-100 font-medium">
                          <li className="cat-item cat-item-2">
                            {/* <Link to="/category">Total News &nbsp; &nbsp; {Count.total_news}</Link> */}
                            <Link className='mnglscontent pl-0 pr-2' style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>Total News</span>
                              <span className='rightconinspn'>{Count.total_news}</span>
                            </Link>
                          </li>
                          <li className="cat-item cat-item-3">
                            <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'> Total Category</span>
                              <span className='rightconinspn'>{Count.total_news_category}</span>
                            </Link>
                          </li>
                          <li className="cat-item cat-item-4">
                            <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'> News Source</span>
                              <span className='rightconinspn'>{Count.total_news_source}</span>
                            </Link>


                          </li>
                          <li className="cat-item cat-item-5">
                            <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>News Language</span>
                              <span className='rightconinspn'>{Count.total_news_language}</span>
                            </Link>
                          </li>

                          <li className="cat-item cat-item-6">
                            <Link className='mnglscontent pl-0 pr-2' to="/category" style={{ display: "flex", justifyContent: "space-between" }}>
                              <span style={{ fontFamily: "Times Roman" }} className='leftconinspn'>News Country</span>
                              <span className='rightconinspn'>{Count.total_news_country}</span>
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
            {Flag.length ? <div className="mb-50">
              <h5>Countries related to this news: </h5>
              {
                Flag?.map((item) => {

                  return (<>
                    <img onClick={() => GotoCountryDetaile(item.name, item.id)} height='100px' width='100px' src={item.image} />
                  </>)
                })

              }

            </div> : ''}
             <div class="sharethis-inline-reaction-buttons" ></div>

            <div class="author-bio border-radius-10  p-6 my-4 d-flex text-start gap-30">
              <div className='text-center'>
                <div class="author-image ">
                  <a onClick={() => GotoSourceDetaile(List.source?.name, List.source?.id)}><img src={List.source?.logo} alt="" class="avatar" /></a>
                </div>
                <a href={List.source?.url} target='_blank' class="author-bio-link text-muted mt-2">
                  <FontAwesomeIcon icon={faGlobe} />
                </a>
              </div>
              <div class="author-info">
                <h3><span class="vcard author"><span class="fn"><a href="author.html" title="Posts by Robert" rel="author">{List.source?.name}</a></span></span></h3>
                {/* <h5 class="text-muted">
                  <span class="mr-15">Elite author</span>
                  <i class="ti-star"></i>
                  <i class="ti-star"></i>
                  <i class="ti-star"></i>
                  <i class="ti-star"></i>
                  <i class="ti-star"></i>
                </h5> */}
                <div class="author-description">{List.source?.description}</div>


                {/* <div class="author-social">
                    <ul class="author-social-icons">
                      <li class="author-social-link-facebook"><a href="#" target="_blank"><i class="ti-facebook"></i></a></li>
                      <li class="author-social-link-twitter"><a href="#" target="_blank"><i class="ti-twitter-alt"></i></a></li>
                      <li class="author-social-link-pinterest"><a href="#" target="_blank"><i class="ti-pinterest"></i></a></li>
                      <li class="author-social-link-instagram"><a href="#" target="_blank"><i class="ti-instagram"></i></a></li>
                    </ul>
                  </div> */}
              </div>
            </div>



          </div>
        </main>
        <Footer />
      </div>
      <div className="dark-mark" />
    </div>

  )
}
