import { SELECTED_TITLE, SELECTED_DESCRIPTION, SELECTED_IMAGE } from "../actions/confige";

const initialState = {
    selectedMetaTitle: '',
    selectedMetaDescription: '',
    selectedMetaImage: ''

};

export default function MetaReducer(state = initialState, action) {
    switch (action.type) {
        case SELECTED_TITLE:
            return {
                ...state,
                selectedMetaTitle: action.selectedTitle

            }
        case SELECTED_DESCRIPTION:
            return {
                ...state,
                selectedMetaDescription: action.selectedDescription
            }
        case SELECTED_IMAGE:
            return {
                ...state,
                selectedMetaImage: action.selectedImage
            }
        default:
            return state
    }
}