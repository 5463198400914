import { combineReducers } from "redux";
import SourceReducer from "./sourceReducer";
import CategoryReducer from "./categoryReducer";
import CountryReducer from "./countryReducer";
import MetaReducer from "./metaReducer";
import SearchReducer from "./searchReducer";
import FinanceReducer from "./financeReducer";
import TabReducer from "./tabReducer";
const rootReducer = combineReducers({
        SourceObj : SourceReducer,
        CategoryObj : CategoryReducer,
        CountryObj : CountryReducer,
        MetaObj : MetaReducer,
        SearchObj : SearchReducer,
        FinanceObj : FinanceReducer,
        TabObj : TabReducer
       
});

export default rootReducer;