import React, { useEffect, useState } from 'react'
import Header from '../Header'
import axios from 'axios';
import { ApiUrl } from '../../common/UrlContant';
import Loader from '../../common/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { setSelectedSource } from '../../actions/sourceAction';
import { setSelectedCategory } from '../../actions/CategoryAction';
import { useDispatch } from 'react-redux';
import { setSelectedFinance } from '../../actions/FinanceAction';
import { setSelectedMeta } from '../../actions/MetaAction';


export default function Finance() {
    const [ListFinance, setListFinance] = useState([])
    const [page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false)
  const [count , setCount] =useState(0)
  const dispatch = useDispatch();
  const navigation = useNavigate();
    const GetFinance = () => {
        setLoading(true)
        axios.get(ApiUrl + `api/news/finance-news/?page=${page}&size=24`).then((res) => {
          setCount(res.data.count)
          Metadispatch('Finance-News' , 'Finance-News Deatiles' , 'https://basevik.fra1.cdn.digitaloceanspaces.com/default/site_share.jpg')
          if (page <= 1) {
           
            setListFinance(res.data.results);
    
          }
          else {
            setListFinance([...ListFinance, ...res.data.results]);
          }
          setLoading(false)
        })
    
      }
      const GotoNewsDetaile = (Sname, Sid) => {
        localStorage.setItem('selectedFinace', Sid);
        localStorage.setItem('authorname', Sname);
        dispatch(
          setSelectedFinance(Sid, Sname)
        ).then((res) => {
          // const URL =  Sname.replace(/\s+/g, '-')
          navigation('/Finance-news/' + Sname.replace(/\s+/g, '-'));
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      };
      const GotoSourceDetaile = (Sname, Sid) => {
        localStorage.setItem('selectedSource', Sid);
        localStorage.setItem('authorname', Sname);
        dispatch(
          setSelectedSource(Sid, Sname)
        ).then((res) => {
          const URL = Sname.replace(/\s+/g, '-')
          navigation('/Finance-author/' + URL);
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      };
      const Metadispatch = (title, desc, image) => {
        dispatch(
          setSelectedMeta(title, desc, image)
        ).then((res) => {
          // setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
          console.log(res);
        })
      }
      const GotoCategoryDetaile = (Cname, Cid, Counter) => {
        localStorage.setItem('selectedCid', Cid);
        localStorage.setItem('selectedCname', Cname);
        localStorage.setItem('selectedCount', Counter);
        dispatch(
          setSelectedCategory(Cname, Cid, Counter)
        ).then((res) => {
          const URL = Cname.replace(/\s+/g, '-')
          navigation('/category/' + URL);
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      }

      useEffect(()=>{
        GetFinance();
      },[page])

  return (
    <>
     {Loading ?
        <Loader /> : ""}
    <Header/>
    <div className='grid'>

        {ListFinance.map((item) => {
          return (
            

            <article className=" background-white border-radius-10 mb-30 wow fadeIn animated grid__item" >

              <div className="post-thumb mr-15 border-radius-15 img-hover-scale">
                <a href={'/Finance-news/' + item.title.replace(/\s+/g, '-')} className="color-white" onClick={() => GotoNewsDetaile(item.title, item.id)} >
                  <img className="border-radius-15" src={item.image} alt />
                </a>
              </div>
              <div className="post-content media-body">
                <div className="entry-meta mb-15 mt-10">
                  <Link className="entry-meta meta-2" onClick={() => GotoCategoryDetaile(item.category?.name, item.category?.id, item.category?.news_count)}><span className="post-in text-danger font-x-small">{item.category?.name}</span></Link>
                </div>
                <h5 className="post-title mb-15 text-limit-2-row">
                  {/* <span className="post-format-icon">
                    <ion-icon name="videocam-outline" />
                  </span> */}
                  <a href={'/Finance-news/' + item.title.replace(/\s+/g, '-')} onClick={() => GotoNewsDetaile(item.title, item.id)}>{item.title}</a></h5>
                <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                  <span className="post-by">By <a onClick={() => GotoSourceDetaile(item.source?.name, item.source?.id)}>{item.source?.name}</a></span>
                  {/* <span className="post-on">{item.added_on}</span> */}
                  <span className="time-reading d-none d-lg-block">{new Date(item.published_on).getFullYear()}-{new Date(item.published_on).getMonth() + 1}-{new Date(item.published_on).getDate()}  &nbsp; {new Date(item.published_on).getHours()}:{new Date(item.published_on).getMinutes()}</span>
                </div>
              </div>

            </article>

          )
        })}
      </div>
      <div className="pagination-area mb-30">
        <nav aria-label="Page navigation example">

          <ul className="pagination justify-content-center">
            <div style={{ textAlign: "center", paddingBottom: "10px" }}>
              {
                ListFinance.length < count ?
                  <button onClick={() => setPage((page) => page + 1)} class="custom-btn btn-11"><span onClick={() => PageToptop()} >Load More...</span></button>
                  : ''
              }

            </div>

          </ul>
        </nav>
        </div>
    </>
  )
}
