import { SELECTEDTAB } from "./confige";

export const setSelectedTab = ( selectedTabname ) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTEDTAB , selectedTabname }
           
        );
        resolve();
    })
}