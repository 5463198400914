import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { setSelectedSearch } from '../actions/searchAction';
import { useDispatch } from 'react-redux';
import { CountryLogo, Home } from '../common/ByDomain';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import "./Header.css"
import { FiSearch } from "react-icons/fi";

export default function Header() {

  const [hide, setHide] = useState(false);
  const [searchhide, setsearchhide] = useState(false)
  const [search, setsearch] = useState('')
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const SearchHandler = (e) => {
    console.log(search);
    localStorage.setItem('selectedSearch', search);
    dispatch(
      setSelectedSearch(search)
    ).then((res) => {
      const URL = search.replace(/\s+/g, '-');
      navigation('/advance-search/' + URL);
      // navigation(`/advance-search?query=${search}`)

    }).catch((err) => {
      console.log("redux ---", err);
    })
    setsearch('')

  };


  return (
    <>
      {/* <header className="main-header header-style-2 mb-40">
      <div className="header-bottom header-sticky background-white text-center">
        <div className="scroll-progress gradient-bg-1" />
        <div className="mobile_menu d-lg-none d-block" />
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-3">
              <div className="header-logo d-none d-lg-block">
                <Link to="/">
                  <img className="logo-img d-inline" src="assets/imgs/logo.svg" alt />
                </Link>
              </div>
              <div className="logo-tablet d-md-inline d-lg-none d-none">
                <Link to="/">
                  <img className="logo-img d-inline" src="assets/imgs/logo.svg" alt />
                </Link>
              </div>
              <div className="logo-mobile d-block d-md-none">
                <Link to="/">
                  <img className="logo-img d-inline" src="assets/imgs/favicon.svg" alt />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 col-md-9 main-header-navigation">
              <div className="main-nav text-left float-lg-left float-md-right">
                <ul className="mobi-menu d-none menu-3-columns" id="navigation">
                  <li className="cat-item cat-item-2"><Link to="/#">Global Economy</Link></li>
                  <li className="cat-item cat-item-3"><Link to="/#">Environment</Link></li>
                  <li className="cat-item cat-item-4"><Link to="/#">Religion</Link></li>
                  <li className="cat-item cat-item-5"><Link to="/#">Fashion</Link></li>
                  <li className="cat-item cat-item-6"><Link to="/#">Terrorism</Link></li>
                  <li className="cat-item cat-item-7"><Link to="/#">Conflicts</Link></li>
                  <li className="cat-item cat-item-2"><Link to="/#">Scandals</Link></li>
                  <li className="cat-item cat-item-2"><Link to="/#">Executive</Link></li>
                  <li className="cat-item cat-item-2"><Link to="/#">Foreign policy</Link></li>
                  <li className="cat-item cat-item-2"><Link to="/#">Healthy Living</Link></li>
                  <li className="cat-item cat-item-3"><Link to="/#">Medical Research</Link></li>
                  <li className="cat-item cat-item-4"><Link to="/#">Children’s Health</Link></li>
                  <li className="cat-item cat-item-5"><Link to="/#">Around the World</Link></li>
                  <li className="cat-item cat-item-6"><Link to="/#">Ad Choices</Link></li>
                  <li className="cat-item cat-item-7"><Link to="/#">Mental Health</Link></li>
                  <li className="cat-item cat-item-2"><Link to="/#">Media Relations</Link></li>
                </ul>
                <nav>
                  <ul className="main-menu">
                    <li>
                      <Link to="/"><span className="mr-15">
                          <ion-icon name="home-outline" />
                        </span>Home</Link>
                      <ul className="sub-menu text-muted font-small">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/trends">Trands</Link></li>
                        <li><Link to="/home-2">Contact</Link></li>
                      </ul>
                    </li>
                    <li><Link to="/trends"><span className="mr-15">
                          <ion-icon name="megaphone-outline" />
                        </span>Trands</Link></li>
                    <li className="mega-menu-item">
                      <Link to="/#">
                        <span className="mr-15">
                          <ion-icon name="desktop-outline" />
                        </span>Layouts
                      </Link>
                      <div className="sub-mega-menu sub-menu-list row text-muted font-small">
                        <ul className="col-md-2">
                          <li><strong>Archive layout</strong></li>
                          <li><Link to="/category">Category list</Link></li>
                          <li><Link to="/category-grid">Category grid</Link></li>
                          <li><Link to="/category-big">Category big</Link></li>
                          <li><Link to="/category-metro">Category metro</Link></li>
                        </ul>
                        <ul className="col-md-2">
                          <li><strong>Post format</strong></li>
                          <li><Link to="/single">Post standard</Link></li>
                          <li><Link to="/single-video">Post video</Link></li>
                          <li><Link to="/single-gallery">Post gallery</Link></li>
                          <li><Link to="/single-audio">Post audio</Link></li>
                          <li><Link to="/single-image">Post image</Link></li>
                          <li><Link to="/single-full">Post full width</Link></li>
                        </ul>
                        <ul className="col-md-2">
                          <li><strong>Pages</strong></li>
                          <li><Link to="/typography">Typography</Link></li>
                          <li><Link to="/about">About us</Link></li>
                          <li><Link to="/contact">Contact us</Link></li>
                          <li><Link to="/search">Search</Link></li>
                          <li><Link to="/author">Author</Link></li>
                          <li><Link to="/404">404 page</Link></li>
                        </ul>
                        <div className="col-md-6 text-right">
                          <Link to="/#"><img className="border-radius-10" src="assets/imgs/ads-2.jpg" alt /></Link>
                        </div>
                      </div>
                    </li>
                    <li className="mega-menu-item">
                      <Link to="/category"><span className="mr-15">
                          <ion-icon name="megaphone-outline" />
                        </span>Mega</Link>
                      <div className="sub-mega-menu">
                        <div className="nav flex-column nav-pills" role="tablist">
                          <Link className="nav-link active" data-toggle="pill" to="/#news-0" role="tab">All</Link>
                          <Link className="nav-link" data-toggle="pill" to="/#news-1" role="tab">Entertaiment</Link>
                          <Link className="nav-link" data-toggle="pill" to="/#news-2" role="tab">Fashion</Link>
                          <Link className="nav-link" data-toggle="pill" to="/#news-3" role="tab">Life Style</Link>
                        </div>
                        <div className="tab-content">
                          <div className="tab-pane show active" id="news-0" role="tabpanel">
                            <div className="row">
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-1.jpg" alt />
                                  </Link>
                                  <span className="top-right-icon background2">
                                    <i className="mdi mdi-audiotrack" />
                                  </span>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">The litigants on the screen are not actors </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-2.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">Not a bit of hesitation, you better think twice</h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-3.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">We got a right to pick a little fight, Bonanza </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-8.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">My entrance exam was on a book of matches </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane" id="news-1" role="tabpanel">
                            <div className="row">
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-5.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">The litigants on the screen are not actors </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-6.jpg" alt />
                                  </Link>
                                  <span className="top-right-icon background3">
                                    <i className="mdi mdi-videocam" />
                                  </span>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">Not a bit of hesitation, you better think twice</h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-7.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">We got a right to pick a little fight, Bonanza </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-8.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">My entrance exam was on a book of matches </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane" id="news-2" role="tabpanel">
                            <div className="row">
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-9.jpg" alt />
                                  </Link>
                                  <span className="top-right-icon background2">
                                    <i className="mdi mdi-audiotrack" />
                                  </span>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">The litigants on the screen are not actors </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-10.jpg" alt />
                                  </Link>
                                  <span className="top-right-icon background8">
                                    <i className="mdi mdi-favorite" />
                                  </span>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">Not a bit of hesitation, you better think twice</h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-11.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">We got a right to pick a little fight, Bonanza </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-12.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">My entrance exam was on a book of matches </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane" id="news-3" role="tabpanel">
                            <div className="row">
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-13.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">The litigants on the screen are not actors </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-14.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">Not a bit of hesitation, you better think twice</h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-15.jpg" alt />
                                  </Link>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">We got a right to pick a little fight, Bonanza </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 post-module-1">
                                <div className="post-thumb d-flex border-radius-5 img-hover-scale mb-15">
                                  <Link to="/single">
                                    <img src="assets/imgs/news-16.jpg" alt />
                                  </Link>
                                  <span className="top-right-icon background2">
                                    <i className="mdi mdi-audiotrack" />
                                  </span>
                                </div>
                                <div className="post-content media-body">
                                  <h6 className="post-title mb-10 text-limit-2-row">My entrance exam was on a book of matches </h6>
                                  <div className="entry-meta meta-1 font-x-small color-grey mt-10">
                                    <span className="post-on">25 April</span>
                                    <span className="hit-count has-dot">126k Views</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                     <li><Link to="/category-metro"><span className="mr-15">
                          <ion-icon name="film-outline" />
                        </span>Video</Link></li>
                    <li><Link to="/contact"><span className="mr-15">
                          <ion-icon name="mail-unread-outline" />
                        </span>Contact</Link></li>
                  </ul>
                  <div className="d-inline ml-50 tools-icon">
                    <Link className="red-tooltip text-danger" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Hot Topics">
                      <ion-icon name="flame-outline" />
                    </Link>
                    <Link className="red-tooltip text-primary" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Trending">
                      <ion-icon name="flash-outline" />
                    </Link>
                    <Link className="red-tooltip text-success" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Notifications">
                      <ion-icon name="notifications-outline" />
                      <span className="notification bg-success">5</span>
                    </Link>
                  </div>
                </nav>
              </div>
              <form action="#" method="get" className="search-form d-lg-inline float-right position-relative mr-30 d-none">
                <input type="text" className="search_field" placeholder="Search" defaultValue name="s" />
                <span className="search-icon"><i className="ti-search mr-5" /></span>
              </form>
              <div className="off-canvas-toggle-cover">
                <div className="off-canvas-toggle hidden d-inline-block ml-15" id="off-canvas-toggle">
                  <ion-icon name="grid-outline" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header> */}
      {/* <header class="main-header header-style-2 mb-40">
        <div class="header-bottom header-sticky background-white text-center">
          <div class="scroll-progress gradient-bg-1"></div>
          <div class="mobile_menu d-lg-none">
            <div class="slicknav_menu bg-transparent m-0 p-0 fs-6">

              <div class="container slicknav_open">

                <a onClick={() => setHide(hide => !hide)} aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style={{ outline: "none" }}>
                  <span class="slicknav_menutxt d-none d-lg-block">MENU</span>
                  <span class="slicknav_icon">
                    <span class=""><i class="ti-menu-alt"></i></span>
                    <span class="ti-close"></span>
                  </span>
                </a>
              </div>

              {hide ? <ul class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu">
                <li class="menu-item-has-children">
                  <a href="/"><span class="mr-15">
                    <ion-icon name="home-outline"></ion-icon>
                  </span>{Home}</a>

                </li>
                <li class="menu-item-has-children">
                  <a href="/international"><span class="mr-15">
                    <ion-icon name="megaphone-outline"></ion-icon>
                  </span>World</a>

                </li>
              </ul>
                : ""} </div>
          </div>
          <div class="container-lg  container-fluid">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="header-logo  logoinnsec">
                  <a href="/">
                    <img class="logo-img d-inline" src="assets/imgs/logo.svg" alt="" />
                    <h2 class="logo-img mb-0 " style={{ fontFamily: " times new Roman italic " }}>{CountryLogo} </h2>
                  </a>
                </div>

              </div >
              <div class="col-lg-8  main-header-navigation d-flex flex-wrap justify-content-between">
                <div class="main-nav text-left float-lg-left float-md-right">
                  <nav>
                    <ul class="main-menu d-none d-lg-inline">
                      <li class="menu-item-has-children">
                        <a href="/"><span class="mr-15">
                          <ion-icon name="home-outline"></ion-icon>
                        </span>{Home}</a>

                      </li>
                      <li class="menu-item-has-children">
                        <a href="/international"><span class="mr-15">
                          <ion-icon name="megaphone-outline"></ion-icon>
                        </span>World</a>

                      </li>
                    </ul>
                  </nav>
                </div>
                <Form
                  inline
                  class="search-form d-xl-inline float-right position-relative mr-30 "
                >
                  <InputGroup >
                    <FormControl
                      type="text"
                      placeholder="Search News"
                      className="search_field d-none d-lg-block"
                      value={search} onChange={(e) => { setsearch(e.target.value) }}
                    />
                    <InputGroup.Append>
                      <button onClick={() => SearchHandler()} class="search-icon" type="submit">
                        <i style={{ fontSize: "14px" }} class="ti-search mr-5 d-none d-lg-block"></i>
                      </button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </div>
            </div >
          </div >
        </div >
      </header > */}

      <header className='head_mgb'>
        <Navbar expand="lg" bg="white" className='py-lg-0 py-3'>
          <Container>
            <Navbar.Brand href="/" className='py-0 mr-0 head_logo' style={{ fontFamily: " times new Roman italic " }}>{CountryLogo}</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className='border-0' />
            <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-center'>
              <Nav className='main_gap mt-lg-0 mt-3'>
                <Nav.Link href="/" className='py-lg-3'>
                  <div className='d-flex align-items-center nav_gap'>
                    <div>
                      <ion-icon name="home-outline"></ion-icon>
                    </div>
                    <div className='nav_text'>{Home}</div>
                  </div>
                </Nav.Link>
                <Nav.Link href="/international" className='py-lg-3'>
                  <div className='d-flex align-items-center nav_gap'>
                    <div>
                      <ion-icon name="megaphone-outline"></ion-icon>
                    </div>
                    <div className='nav_text'>World</div>
                  </div>
                </Nav.Link>
                <Nav.Link href="/Finance" className='py-lg-3'>
                  <div className='d-flex align-items-center nav_gap'>
                    <div>
                      <ion-icon name="book-outline"></ion-icon>
                    </div>
                    <div className='nav_text'>Finance</div>
                  </div>
                </Nav.Link>
                <Nav.Link href="/country" className='py-lg-3 d-none custom-block'>
                  <div className='d-flex align-items-center nav_gap'>
                    <div>
                      <ion-icon name="earth-outline"></ion-icon>
                    </div>
                    <div className='nav_text'>Country</div>
                  </div>
                </Nav.Link>
              </Nav>
              <Form className='d-none nav-forms'>
                <div className='d-flex align-items-center head_form'>
                  <Form.Control
                    type="search"
                    placeholder="Search News"
                    className="d-block py-0 w-100"
                    aria-label="Search"
                    value={search}
                    onChange={(e) => { setsearch(e.target.value) }}
                  />
                  <i onClick={() => SearchHandler()} class="ti-search serch_icon"></i>
                </div>
              </Form>
            </Navbar.Collapse>
            <Form className='d-none d-lg-block'>
              <div className='d-flex align-items-center head_form'>
                <Form.Control
                  type="text"
                  placeholder="Search News"
                  className="d-block py-0 w-100"
                  aria-label="Search"
                  value={search} onChange={(e) => { setsearch(e.target.value) }}
                />
                <i onClick={() => SearchHandler()} class="ti-search serch_icon"></i>
              </div>
            </Form>
          </Container>
        </Navbar>
      </header >
    </>
  )
}
