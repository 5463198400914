import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import MetaTag from "./common/MetaTag";
import router from "./router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Crawel from "./common/crawel";

function App() {
  return (
    <div>
      <Crawel />
      <HelmetProvider>
          <MetaTag/>
      <BrowserRouter>
        <Routes>
          {router.map((item, k) => {
            return (
              <Route
                key={k}
                element={<item.element />}
                path={item.path}
                exact={item.exact}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
      </HelmetProvider>

    </div>
  );
}


export default App;
