import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Button, NavDropdown } from 'react-bootstrap'
import axios from 'axios'
import { ApiUrl } from '../common/UrlContant'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useNavigate } from 'react-router-dom'
import './World.css';
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSource } from '../actions/sourceAction'
import { setSelectedCategory } from '../actions/CategoryAction'

export default function AdvanceFilter() {

    const SearchQuery = useSelector((state) => state.SearchObj.slectedSearchname || localStorage.getItem('selectedSearch'));
    const [ListTrands, setListTrands] = useState([]);
    const [page, setPage] = useState(1);
    const [Loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)
    const dispatch = useDispatch()
    const navigation = useNavigate();

    const searchResult = () => {
        setLoading(true)
        axios.get(ApiUrl + `api/news/all-news/?query=${SearchQuery}&page=${page}&size=20`).then((res) => {
            setCount(res.data.count)
            if (page <= 1)
                setListTrands(res.data.results);
                else
                setListTrands([...ListTrands , ...res.data.results]);
            })
            setLoading(false);

    }
    const GotoNewsDetaile = (Sname, Sid) => {
        localStorage.setItem('selectedSource', Sid);
        localStorage.setItem('authorname', Sname);
        dispatch(
          setSelectedSource(Sid, Sname)
        ).then((res) => {
          // const URL =  Sname.replace(/\s+/g, '-')
          navigation('/news/' + Sname.replace(/\s+/g, '-'));
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      };
      const GotoCategoryDetaile = (Cname, Cid, Counter) => {
        localStorage.setItem('selectedCid', Cid);
        localStorage.setItem('selectedCname', Cname);
        localStorage.setItem('selectedCount', Counter);
        dispatch(
          setSelectedCategory(Cname, Cid, Counter)
        ).then((res) => {
          const URL = Cname.replace(/\s+/g, '-')
          navigation('/category/' + URL);
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      }

      const GotoSourceDetaile = (Sname, Sid) => {
        localStorage.setItem('selectedSource', Sid);
        localStorage.setItem('authorname', Sname);
        dispatch(
          setSelectedSource(Sid, Sname)
        ).then((res) => {
          const URL = Sname.replace(/\s+/g, '-')
          navigation('/author/' + URL);
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      };
    useEffect(() => {
        searchResult();
    }, [page])




    return (
        <>
            <Header />
            { ListTrands.length ? <div>
            <div className='grid'>
                {ListTrands.map((item) => {
                    return (
                        item.image &&

                        <article className=" background-white border-radius-10 mb-30 wow fadeIn animated grid__item" >

                            <div className="post-thumb mr-15 border-radius-15 img-hover-scale">
                                <a href={'/news/' + item.title.replace(/\s+/g, '-')} className="color-white" onClick={() => GotoNewsDetaile(item.title, item.id)} >
                                    <img className="border-radius-15" src={item.image} alt />
                                </a>
                            </div>
                            <div className="post-content media-body">
                                <div className="entry-meta mb-15 mt-10">
                                    <Link className="entry-meta meta-2" onClick={() => GotoCategoryDetaile(item.category?.name, item.category?.id, item.category?.news_count)}><span className="post-in text-danger font-x-small">{item.category?.name}</span></Link>
                                </div>
                                <h5 className="post-title mb-15 text-limit-2-row">
                                   
                                    <a href={'/news/' + item.title.replace(/\s+/g, '-')} onClick={() => GotoNewsDetaile(item.title, item.id)}>{item.title}</a></h5>
                                <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                                    <span className="post-by">By <a onClick={() => GotoSourceDetaile(item.source?.name, item.source?.id)}>{item.source?.name}</a></span>
                                    {/* <span className="post-on">{item.added_on}</span> */}
                                    <span className="time-reading d-none d-lg-block ">{new Date(item.published_on).getFullYear()}-{new Date(item.published_on).getMonth() + 1}-{new Date(item.published_on).getDate()}  &nbsp; {new Date(item.published_on).getHours()}:{new Date(item.published_on).getMinutes()}</span>
                                </div>
                            </div>

                        </article>
                    )
                })}
            </div>
            <div className="pagination-area mb-30">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                            {
                                ListTrands.length < count ? 
                                <button onClick={() => setPage((page) => page + 1)} class="custom-btn btn-11"><span>Load More...</span></button>
                                 : ''
                            }

                        </div>
                    </ul>
                </nav>
            </div></div> : <h1 style={{textAlign:'center'}}>No News Found</h1> }
        </>
    )
}
