import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ApiUrl } from '../common/UrlContant';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSource } from '../actions/sourceAction';
import Loader from '../common/Loader';
import { setSelectedMeta } from '../actions/MetaAction';
import { cacheable } from '../common/Caches';

export default function CountryDetaile() {
  const [Country, setCountry] = useState([]);
  const [CatCounter, setCatCounter] = useState();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1)
  const [Next, setNext] = useState(0);
  const [Previous, setPrevious] = useState(0);
  const [Loading, setLoading] = useState(false)
  const navigation = useNavigate();
  const Countryname = useSelector((state) => state.CountryObj.selectedCountryname || localStorage.getItem('selectedCountryname'));
  const Countryid = useSelector((state) => state.CountryObj.selectedCountryId || localStorage.getItem('selectedCountryid'));

  const GetCategory = () => {
    setLoading(true)
    axios.get(ApiUrl + `api/news/all-news/?country_id=${Countryid}&page=${page}&size=30`).then((res) => {
      setCatCounter(res.data.count);
      Metadispatch(Countryname)
      if (page <= 1)
        setCountry(res.data.results);
      else
        setCountry([...Country, ...res.data.results]);

      setLoading(false)
    })
  }

  

  const GotoSourceDetaile = (Sname, Sid) => {
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedSource(Sid, Sname)
    ).then((res) => {
      const URL = Sname.replace(/\s+/g, '-')
      navigation('/author/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }
  
  const Metadispatch = (title, desc, image) => {
    dispatch(
      setSelectedMeta(title, desc, image)
    ).then((res) => {
      // setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
      console.log(res);
    })
  }
  const GotoNewsDetaile = (Sname, Sid) => {
    localStorage.setItem('selectedSource', Sid);
    localStorage.setItem('authorname', Sname);
    dispatch(
      setSelectedSource(Sid, Sname)
    ).then((res) => {
      const URL = Sname.replace(/\s+/g, '-')
      navigation('/news/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }

  useEffect(() => {
    GetCategory();
    cacheable(localStorage.setItem('testList',JSON.stringify(Country)))

  }, [page])
  return (
    <div>
      {
        Loading ? <Loader /> : ''
      }
      <div className="main-wrap">
        <aside id="sidebar-wrapper" className="custom-scrollbar offcanvas-sidebar position-right">
          <button className="off-canvas-close"><i className="ti-close" /></button>
          <div className="sidebar-inner">
            <div className="siderbar-widget mb-50 mt-30">
              <form action="#" method="get" className="search-form position-relative">
                <input type="text" className="search_field" placeholder="Search" defaultValue name="s" />
                <span className="search-icon"><i className="ti-search mr-5" /></span>
              </form>
            </div>
            <div className="sidebar-widget mb-50">
              <div className="widget-header mb-30">
                <h5 className="widget-title">Top <span>Trending</span></h5>
              </div>
              <div className="post-aside-style-2">
                <ul className="list-post">
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-2.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">4m ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-3.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">3h ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-5.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Reflections from a Token Black Friend</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">4h ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="mb-30 wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-7.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">How to Identify a Smart Person in 3 Minutes</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">5h ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="wow fadeIn animated">
                    <div className="d-flex">
                      <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                        <Link className="color-white" to="/single">
                          <img src="assets/imgs/thumbnail-8.jpg" alt="" />
                        </Link>
                      </div>
                      <div className="post-content media-body">
                        <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Blackface Minstrel Songs Don’t Belong in Children’s Music Class</Link></h6>
                        <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                          <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                          <span className="post-on">5h30 ago</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sidebar-widget widget_tag_cloud mb-50">
              <div className="widget-header tags-close mb-20">
                <h5 className="widget-title mt-5">Tags Cloud</h5>
              </div>
              <div className="tagcloud">
                <Link to="/category">Beauty</Link>
                <Link to="/category">Book</Link>
                <Link to="/category">Design</Link>
                <Link to="/category">Fashion</Link>
                <Link to="/category">Lifestyle</Link>
                <Link to="/category">Travel</Link>
                <Link to="/category">Science</Link>
                <Link to="/category">Health</Link>
                <Link to="/category">Sports</Link>
                <Link to="/category">Arts</Link>
                <Link to="/category">Books</Link>
                <Link to="/category">Style</Link>
              </div>
            </div>
            <div className="sidebar-widget widget-ads mb-30">
              <div className="widget-header tags-close mb-20">
                <h5 className="widget-title mt-5">Your Ads Here</h5>
              </div>
              <Link to="/assets/imgs/news-1.jpg" className="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s">
                <img className="border-radius-10" src="assets/imgs/ads-1.jpg" alt="" />
              </Link>
            </div>
          </div>
        </aside>
        <Header />
        <main className="position-relative">
          <div className="archive-header text-center mb-50">
            <div className="container">
              <h2>
                <span className="text-dark">{Countryname}</span>
                <span className="post-count">{CatCounter} articles</span>
              </h2>
              <div className="breadcrumb">
                <span className="no-arrow"><i className="ti ti-location-pin mr-5" />You are here:</span>
                <Link to="/" rel="nofollow">Home</Link>
                <span />
                {Countryname}
              </div>
            </div>
          </div>
          <div className="container">

            <div className="row mb-50 justify-content-center">
              <div className="widget-header mb-30 col-12">
                <h5 className="widget-title">{CatCounter} news about {Countryname}</h5>
              </div>
              {Country.map((item) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                    <div className="sidebar-widget">

                      <div className="post-aside-style-2">

                        <div className="d-flex">
                          <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale" style={{ width: "160px", height: "103px", borderRadius: "5px" }} >
                            <a href={'/news/' + item.title.replace(/\s+/g, '-')} className="color-white" onClick={() => GotoNewsDetaile(item.source?.name, item.id)}>
                              <img src={item.image} alt="" />
                            </a>
                          </div>
                          <div className="post-content media-body">
                            <h6 className="post-title mb-10 text-limit-2-row"><a href={'/news/' + item.title.replace(/\s+/g, '-')} onClick={() => GotoNewsDetaile(item.source?.name, item.id)}>{item.title}</a></h6>
                            <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                              <span className="post-by" onClick={() => GotoSourceDetaile(item.source?.name, item.source?.id)}>By <a>{item.source?.name}</a></span>
                              {/* <span className="post-on">{new Date(item.published_on).getFullYear()}-{new Date(item.published_on).getMonth() + 1}-{new Date(item.published_on).getDate()}  &nbsp; {new Date(item.published_on).getHours()}:{new Date(item.published_on).getMinutes()}</span> */}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )
              })
              }
              <div className="pagination-area mb-30">
                <nav aria-label="Page navigation example">

                  <ul className="pagination justify-content-center">
                    <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                      {
                        Country.length < CatCounter ?
                          <button onClick={() => setPage((page) => page + 1)} class="custom-btn btn-11"><span onClick={() => PageToptop()} >Load More...</span></button>
                          : ''
                      }

                    </div>

                  </ul>
                </nav>
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="sidebar-widget">
                <div className="widget-header mb-30">
                  <h5 className="widget-title">Editor <span>Picked</span></h5>
                </div>
                <div className="post-aside-style-1 border-radius-10 p-20 bg-white">
                  <ul className="list-post">
                    <li className="mb-20">
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-4.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Federal arrests show no sign that antifa plotted protests</Link></h6>
                        </div>
                      </div>
                    </li>
                    <li className="mb-20">
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-15.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">How line-dried laundry gets that fresh smell</Link></h6>
                        </div>
                      </div>
                    </li>
                    <li className="mb-20">
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-16.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Traveling tends to magnify all human emotions</Link></h6>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-15.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">How line-dried laundry gets that fresh smell</Link></h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="sidebar-widget">
                <div className="widget-header mb-30">
                  <h5 className="widget-title">Most <span>Popular</span></h5>
                </div>
                <div className="post-aside-style-2">
                  <ul className="list-post">
                    <li className="mb-30 wow fadeIn   animated" style={{"visibility":"visible","-webkit-animation-name":"_fadeIn","animation-name":"_fadeIn"}}>
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-2.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Vancouver woman finds pictures and videos of herself online</Link></h6>
                          <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                            <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                            <span className="post-on">4m ago</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="mb-30 wow fadeIn   animated" style={{"visibility":"visible","-webkit-animation-name":"_fadeIn","animation-name":"_fadeIn"}}>
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-3.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">4 Things Emotionally Intelligent People Don’t Do</Link></h6>
                          <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                            <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                            <span className="post-on">3h ago</span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="wow fadeIn   animated" style={{"visibility":"visible","-webkit-animation-name":"_fadeIn","animation-name":"_fadeIn"}}>
                      <div className="d-flex">
                        <div className="post-thumb d-flex mr-15 border-radius-5 img-hover-scale">
                          <Link className="color-white" to="/single">
                            <img src="assets/imgs/thumbnail-5.jpg" alt=""  />
                          </Link>
                        </div>
                        <div className="post-content media-body">
                          <h6 className="post-title mb-10 text-limit-2-row"><Link to="/single">Reflections from a Token Black Friend</Link></h6>
                          <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase">
                            <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                            <span className="post-on">4h ago</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="widget-header mb-30">
                <h5 className="widget-title">Last <span>Comments</span></h5>
              </div>
              <div className="sidebar-widget p-20 border-radius-15 bg-white widget-latest-comments wow fadeIn  animated">
                <div className="post-block-list post-module-6">
                  <div className="last-comment mb-20 d-flex wow fadeIn">
                    <span className="item-count vertical-align">
                      <Link className="red-tooltip author-avatar" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Stormvik - 985 posts"><img src="assets/imgs/authors/author-14.png" alt=""  /></Link>
                    </span>
                    <div className="alith_post_title_small">
                      <p className="font-medium mb-10"><Link to="/single">A writer is someone for whom writing is more difficult than it is for other people.</Link></p>
                      <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase mb-10">
                        <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                        <span className="post-on">4m ago</span>
                      </div>
                    </div>
                  </div>
                  <div className="last-comment d-flex wow fadeIn">
                    <span className="item-count vertical-align">
                      <Link className="red-tooltip author-avatar" to="/#" data-toggle="tooltip" data-placement="top" title data-original-title="Johny - 445 posts"><img src="assets/imgs/authors/author-3.png" alt=""  /></Link>
                    </span>
                    <div className="alith_post_title_small">
                      <p className="font-medium mb-10"><Link to="/single">Teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability.</Link></p>
                      <div className="entry-meta meta-1 font-x-small color-grey float-left text-uppercase mb-10">
                        <span className="post-by">By <Link to="/author">Stormvik</Link></span>
                        <span className="post-on">4m ago</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </main>
        <Footer />
      </div>
      <div className="dark-mark" />
    </div>
  )
}
