import { SELECTED_TITLE, SELECTED_DESCRIPTION, SELECTED_IMAGE } from "./confige";

export const setSelectedMeta = (selectedTitle, selectedDescription, selectedImage) => dispatch => {
    return Promise.all([
        dispatch(
            { type: SELECTED_DESCRIPTION, selectedDescription }),
        dispatch(
            { type: SELECTED_TITLE, selectedTitle }),
        dispatch(
            { type: SELECTED_IMAGE, selectedImage })
            
    ], (resolve, rejected) => {

        resolve();
    })
}