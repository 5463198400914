import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ApiUrl } from '../common/UrlContant';
import { setSelectedCategory } from '../actions/CategoryAction';
import { useDispatch } from 'react-redux';

export default function Footer() {

  const [Category, setCategory] = useState([]);
  const [Count , setCount] = useState({})
  const dispatch = useDispatch();
  const navigation = useNavigate();
  
  const GetCategory = () =>{
  axios.get(ApiUrl+ `api/news/category/?page=1&size=10`).then((res) => {
    setCategory(res.data.results)
  })


  }

  const StaticCount = ()=>{
    axios.get(ApiUrl + `api/news/counter-statistic/`).then((res)=>{
        setCount(res.data);
    })
  }

  const GotoCategoryDetaile = (Cname, Cid , Counter) => {
    localStorage.setItem('selectedCid', Cid);
    localStorage.setItem('selectedCname', Cname);
    localStorage.setItem('selectedCount', Counter);
    dispatch(
      setSelectedCategory(Cname , Cid , Counter)
    ).then((res) => {
      const URL = Cname.replace(/\s+/g, '-')
      navigation('/category/' + URL);

    }).catch((err) => {
      console.log("redux ---", err);
    })
  }

  useEffect(() => {
    GetCategory();
    StaticCount();
  }, [])


  return (
    <div>
      <footer>
        {/* <div className="footer-area pt-50 bg-white">
          <div className="container">
            <div className="row pb-30">
              <div className="col">
                <ul className="float-left mr-30 font-medium">
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Global Economy</Link>
                  </li>
                  <li className="cat-item cat-item-3">
                    <Link to="/category">Environment</Link>
                  </li>
                  <li className="cat-item cat-item-4">
                    <Link to="/category">Religion</Link>
                  </li>
                  <li className="cat-item cat-item-5">
                    <Link to="/category">Fashion</Link>
                  </li>
                  <li className="cat-item cat-item-6">
                    <Link to="/category">Terrorism</Link>
                  </li>
                  <li className="cat-item cat-item-7">
                    <Link to="/category">Conflicts</Link>
                  </li>
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Scandals</Link>
                  </li>
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Executive</Link>
                  </li>
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Foreign policy</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="float-left mr-30 font-medium">
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Healthy Living</Link>
                  </li>
                  <li className="cat-item cat-item-3">
                    <Link to="/category">Medical Research</Link>
                  </li>
                  <li className="cat-item cat-item-4">
                    <Link to="/category">Children’s Health</Link>
                  </li>
                  <li className="cat-item cat-item-5">
                    <Link to="/category">Around the World</Link>
                  </li>
                  <li className="cat-item cat-item-6">
                    <Link to="/category">Ad Choices</Link>
                  </li>
                  <li className="cat-item cat-item-7">
                    <Link to="/category">Mental Health</Link>
                  </li>
                  <li className="cat-item cat-item-8">
                    <Link to="/category">Media</Link>
                  </li>
                  <li className="cat-item cat-item-9">
                    <Link to="/category">Relations</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="float-left mr-30 font-medium">
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Real Estate</Link>
                  </li>
                  <li className="cat-item cat-item-3">
                    <Link to="/category">Commercial</Link>
                  </li>
                  <li className="cat-item cat-item-4">
                    <Link to="/category">Find A Home</Link>
                  </li>
                  <li className="cat-item cat-item-5">
                    <Link to="/category">Mortgage Calculator</Link>
                  </li>
                  <li className="cat-item cat-item-6">
                    <Link to="/category">My Real Estate</Link>
                  </li>
                  <li className="cat-item cat-item-7">
                    <Link to="/category">The High End</Link>
                  </li>
                  <li className="cat-item cat-item-8">
                    <Link to="/category">List Your Home</Link>
                  </li>
                  <li className="cat-item cat-item-9">
                    <Link to="/category">World</Link>
                  </li>
                  <li className="cat-item cat-item-10">
                    <Link to="/category">Magazine</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="float-left mr-30 font-medium">
                  <li className="cat-item cat-item-2">
                    <Link to="/category">U.S.</Link>
                  </li>
                  <li className="cat-item cat-item-3">
                    <Link to="/category">Politics</Link>
                  </li>
                  <li className="cat-item cat-item-4">
                    <Link to="/category">N.Y.</Link>
                  </li>
                  <li className="cat-item cat-item-5">
                    <Link to="/category">Business</Link>
                  </li>
                  <li className="cat-item cat-item-6">
                    <Link to="/category">Opinion</Link>
                  </li>
                  <li className="cat-item cat-item-7">
                    <Link to="/category">Tech</Link>
                  </li>
                  <li className="cat-item cat-item-8">
                    <Link to="/category">Science</Link>
                  </li>
                  <li className="cat-item cat-item-9">
                    <Link to="/category">Health</Link>
                  </li>
                  <li className="cat-item cat-item-10">
                    <Link to="/category">Sports</Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <ul className="float-left mr-30 font-medium">
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Arts</Link>
                  </li>
                  <li className="cat-item cat-item-3">
                    <Link to="/category">Books</Link>
                  </li>
                  <li className="cat-item cat-item-4">
                    <Link to="/category">Style</Link>
                  </li>
                  <li className="cat-item cat-item-5">
                    <Link to="/category">Food</Link>
                  </li>
                  <li className="cat-item cat-item-6">
                    <Link to="/category">Travel</Link>
                  </li>
                  <li className="cat-item cat-item-7">
                    <Link to="/category">T Magazine</Link>
                  </li>
                  <li className="cat-item cat-item-8">
                    <Link to="/category">Real Estate</Link>
                  </li>
                  <li className="cat-item cat-item-9">
                    <Link to="/category">Obituaries</Link>
                  </li>
                  <li className="cat-item cat-item-10">
                    <Link to="/category">Video</Link>
                  </li>
                </ul>
              </div>
              <div className="col d-none d-lg-block">
                <ul className="float-left mr-30 font-medium">
                  <li className="cat-item cat-item-2">
                    <Link to="/category">Total News &nbsp; &nbsp; {Count.total_news}</Link>
                  </li>
                  <li className="cat-item cat-item-3">
                    <Link to="/category">Total Category  &nbsp; &nbsp; {Count.total_news_category} </Link>
                  </li>
                  <li className="cat-item cat-item-4">
                    <Link to="/category">News Source &nbsp; &nbsp; {Count.total_news_source}</Link>
                  </li>
                  <li className="cat-item cat-item-5">
                    <Link to="/category">News Language &nbsp; &nbsp; {Count.total_news_language}</Link>
                  </li>
                  <li className="cat-item cat-item-6">
                    <Link to="/category">News Country  &nbsp; &nbsp; {Count.total_news_country}</Link>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-bottom-area bg-white text-muted">
          <div className="container">
            <div className="footer-border pt-20 pb-20">
              <div className="row d-flex mb-15">
                <div className="col-12">
                  <ul className="list-inline font-small">
                    {/* {
                      Category?.map((item) => {
                        return (
                          <li className="list-inline-item" onClick={() => GotoCategoryDetaile(item.name, item.id, item.news_count)} ><Link >{item.name}</Link></li>
                        )
                      })
                    } */}
                      <a href='https://www.thetimesofgroup.com/about' target='_blank'><li className="list-inline-item ">About us</li></a>
                      <a href='https://www.thetimesofgroup.com/contact' target='_blank'><li className="list-inline-item pl-3">Contact</li></a>
                      <a href='https://www.thetimesofgroup.com/privacy' target='_blank'><li className="list-inline-item pl-3">Privacy policy</li></a>
                      <a href='https://www.thetimesofgroup.com/terms' target='_blank'><li className="list-inline-item pl-3">Terms of service


</li> </a>
                  </ul>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-between">
                <div className="col-12">
                  <div className="footer-copy-right">
                    <p className="font-small text-muted">
                      @{new Date().getFullYear()}, Time Group | All rights reserved | powered by {" "}
                      <Link to="https://www.stormvik.com/" target="_blank">
                        Stormvik
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
