import {  SELECTED_CATCOUNT, SELECTED_CATNAME , SELECTED_CATID } from "../actions/confige";

const initialState ={
    selectedCId : '',
    slectedCname : '',
    slectedCount : ''
};

export default function CategoryReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_CATNAME : 
        return{
            ...state,
            slectedCname  : action.selectedCname
            
        }
        case SELECTED_CATID :
            return{
                ...state,
                selectedCId : action.selectedCid
            }
            case SELECTED_CATCOUNT :
            return{
                ...state,
                slectedCount : action.selectedCount
            }
        default :
        return state
    }
}