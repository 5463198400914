import {SEARCH} from "./confige";

export const setSelectedSearch = ( selectedSearch) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SEARCH , selectedSearch },
           
        );
        resolve();
    })
}