import { SELECTED_SOURCE , SELECTED_AUTHORNAME } from "./confige";

export const setSelectedSource = ( selectedSource , authorname) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTED_SOURCE , selectedSource },
            {type:SELECTED_AUTHORNAME , authorname }
        );
        resolve();
    })
}