import Home from './componet/Home';
import Trands from './componet/Trends';
import Categorymetro from './componet/Categorymetro';
import Categorygrid from './componet/Categorygrid';
import Single from './componet/Single';
import Error from './componet/Error';
import CountryDetaile from './componet/CountryDetaile';
import AdvanceFilter from './componet/AdvanceFilter';
import World from './componet/World';
import MobileCountryPage from './componet/MobileCountryPage';
import Finance from './componet/Finance/Finance';
import FinanceDetaile from './componet/Finance/FinanceDetaile';
import FinanceSourceDetaile from './componet/Finance/FinanceSourceDetaile';
export default [

    {
        path: '/',
        element: () => <Home />,
        exact: true,
    },
    {
        path: '/trends',
        element: () => <Trands />,
        exact: true,
    },{
        path: '/international',
        element: () => <World />,
        exact: true,
    },{
        path: '/author/:name',
        element: () => <Categorygrid />,
        exact: true,
    },{
        path: '/category-big',
        element: () => <Categorybig />,
        exact: true,
    },{
        path: '/category/:name',
        element: () => <Categorymetro />,
        exact: true,
    },{
        path: '/news/:name',
        element: () => <Single />,
        exact: true,
    },{
        path: '/country',
        element: () => <MobileCountryPage />,
        exact: true,
    },{
        path: '/Country/:name',
        element: () => <CountryDetaile />,
        exact: true,
    },{
        path: '/Finance',
        element: () => <Finance />,
        exact: true,
    },
    {
        path: '/Finance-news/:name',
        element: () => <FinanceDetaile />,
        exact: true,
    },{
        path: '/Finance-author/:name',
        element: () => <FinanceSourceDetaile />,
        exact: true,
    },
    {
        path: '/advance-search/:name',
        element: () => <AdvanceFilter />,
        exact: true,
    },{
        path: '/404',
        element: () => <Error />,
        exact: true,
    }

]