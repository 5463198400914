import React, { useEffect, useState } from 'react'

export default function DateTime() {
    const [war, setwar] = useState()
    const [warMonth, setwarMonth] = useState()
useEffect(()=>{
    var a = new Date();
    var days = new Array(7);
    var month = new Array(12);
    days[0] = "Sunday";
    days[1] = "Monday";
    days[2] = "Tuesday";
    days[3] = "Wednesday";
    days[4] = "Thursday";
    days[5] = "Friday";
    days[6] = "Saturday";

    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    setwar(days[a.getDay()]);
    setwarMonth(month[a.getMonth()]);
},[])  
  return (
    <div className="sidebar-widget widget-weather border-radius-10 bg-white mb-30">
                  <div className="d-flex justify-content-center">
                    <div className="font-medium d-flex custmgapinn">
                      <p>{war}</p>
                      <h2>{new Date().getDate()}</h2>
                      <p><strong>{warMonth}</strong></p>
                      <div className="font-medium ">
                      <div id="datetime" className="d-inline-block ml-0">
                        <ul>
                          <li className='text-center'>
                            <span style={{fontSize:"18px"}}>
                              <strong>{new Date().getHours() + ':' + new Date().getMinutes()+ ':' + new Date().getSeconds() }</strong>
                            </span>
                          </li>

                        </ul>
                      </div>
                    </div>
                    </div>
                    
                  </div>
                </div>
  )
}
