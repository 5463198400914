import {SELECTED_FINANCE , SELECTED_FINANCENAME } from "./confige";

export const setSelectedFinance = ( selectedfinancename , selectedfinance  ) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTED_FINANCENAME , selectedfinancename },
            {type:SELECTED_FINANCE , selectedfinance }
            
        );
        resolve();
    })
}