import { SELECTED_AUTHORNAME, SELECTED_SOURCE } from "../actions/confige";

const initialState ={
    selectedSourceId : '',
    slectedAuthername : ''
};

export default function SourceReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_SOURCE : 
        return{
            ...state,
            selectedSourceId  : action.selectedSource
            
        }
        case SELECTED_AUTHORNAME :
            return{
                ...state,
                slectedAuthername : action.authorname
            }
        default :
        return state
    }
}